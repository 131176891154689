import React from "react";
import NativeLazyImage from "./NativeLazyImage";
import "./../styles/ReviewCard.style.css";
import StarRating from "./StarRating";

export default function ReviewCard({ name, avatar, rating, review }) {
  return (
    <div class="card review-card rounded-2 bg-white mb-4 border-light border-1 shadow-sm">
      <div className="card-header bg-white">
        <div className="d-flex align-items-center">
          <span
            className="_avatar_wrap me-3"
            style={{
              backgroundImage: `url(
                "/img/fallback/student.png"
              )`,
            }}
          >
            <NativeLazyImage src={avatar} />
          </span>
          <div>
            <h6 class="card-title mb-0">{name}</h6>
            <StarRating rating={rating} />
          </div>
        </div>
      </div>

      <div class="card-body">
        <p class="card-text">{review}</p>
      </div>
    </div>
  );
}
