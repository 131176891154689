import React from "react";
import NativeLazyImage from "./NativeLazyImage";
import { Link } from "react-router-dom";
import "./../styles/blog_card.style.css";

export default function BlogCard({
  title,
  excerpt,
  date,
  instructor,
  link = "#",
  thumbnail,
  classes = "",
}) {
  return (
    <Link to={link} className="text-decoration-none blog-card">
      <div className={`card ${classes} mb-3`}>
        <span className="_img_wrap">
          <NativeLazyImage src={thumbnail} alt={title} classes="" />
        </span>
        <div class="card-body">
          <h5 class="card-title">{title}</h5>
          {/* <p class="card-text">{excerpt}</p> */}

          <p className="mt-3 mb-0 text-muted small ">
            By <ins>{instructor}</ins>
          </p>
          <p className="mb-1 text-muted small ">On {date}</p>
        </div>
      </div>
    </Link>
  );
}
