import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { cartReducer } from "./slice/CartSlice";
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import persistReducer from "redux-persist/es/persistReducer";
import persistStore from "redux-persist/es/persistStore";
import { userSessionReducer } from "./slice/UserSessionSlice";

/* const store = configureStore({
  devTools: true,
  reducer: {
    cart: cartReducer,
  },
}); */


// Combine your reducers
const rootReducer = combineReducers({
  cart: cartReducer,
  userSession: userSessionReducer
  // Add other reducers here
});


// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  
  // whitelist: ['cart'], // Only persist the cart slice
};

// Create a persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // Needed for redux-persist
    }),
});

// Persistor
const persistor = persistStore(store);

export { store, persistor };


// export default store;
