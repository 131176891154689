import React from "react";
import Countdown from "react-countdown";

export default function QuizzTimer({
  onStartCallback,
  onCompleteCallback,
  timeInMinutes,
}) {
  // Renderer callback with condition
  const renderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return <span className="bg-danger p-3">Timeout</span>;
    } else {
      // Render a countdown

      minutes = minutes.toString().length === 1 ? "0" + minutes : minutes;
      seconds = seconds.toString().length === 1 ? "0" + seconds : seconds;

      return (
        <h4 className="mb-0">
          {minutes}:{seconds}
        </h4>
      );
    }
  };

  let timeInMiliSecs = timeInMinutes * 60000;

  return (
    <div className="quizz-timer">
      <Countdown
        onStart={onStartCallback}
        onComplete={onCompleteCallback}
        date={Date.now() + timeInMiliSecs}
        renderer={renderer}
      />
    </div>
  );
}
