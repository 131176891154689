export default function NativeLazyImage({ src, alt = "", classes = "" }) {
  return (
    <img
      src={src}
      loading="lazy"
      className={`${classes}`}
      alt={alt}
      style={{ width: "100%" }}
    />
  );
}
