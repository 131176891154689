import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ScrollableHorizontalMenu from "../../component/ScrollableHorizontalMenu";
import UnderDevelopmentSection from "../../component/UnderDevelopmentSection";
import MyPurchasesTable from "./component/MyPurchasesTable";

export default function MyPurchasesPage() {
  return (
    <main>
      <Helmet>
        <title>My Purchases | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <ScrollableHorizontalMenu />

      <section>
        <div className="container">
          <MyPurchasesTable />
        </div>
      </section>
    </main>
  );
}
