import {
  faArrowRight,
  faGraduationCap,
  faRupee,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_RECOMMENDED_COURSES } from "../ApiEndPoints";
import CourseCardTile from "../component/CourseCardTile";
import Loader from "../component/Loader";
import { IconFilledPrimaryBtnLink } from "../component/FilledBtn";

export default function CoursesRowWithAction() {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_RECOMMENDED_COURSES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="">
      <div className="container">
        <div className="card p-2 py-3 p-md-5 border-0 bg-lightaccent bg-style-1 rounded-4">
          <div className="row">
            <div className="col-md-12 col-lg-3">
              <h2 className="sec-title-sm">Recommended Course</h2>
              <p className="sec-short-desc">
                Explore our top courses recommended by our top faculties
              </p>

              <IconFilledPrimaryBtnLink
                classes={`me-2 d-none d-lg-inline-block`}
                fontAwesomeIcon={faArrowRight}
                label="View All"
                link="/courses"
              />
            </div>

            <div className="col-md-12 col-lg-9">
              <div className="row">
                {loading && (
                  <div className="col-12 d-flex align-items-center justify-content-center">
                    <Loader classes="text-primary" />
                  </div>
                )}

                {!loading &&
                  courses.map((row, index) => {
                    return (
                      <div
                        className="col-md-6 col-lg-4"
                        key={`course-row-with-action-${index}`}
                      >
                        <CourseCardTile
                          title={row.title}
                          category={row.category_name}
                          thumbnail={serverUrl + row.thumbnail}
                          price={row.price}
                          instructorName={row.instructor_name}
                          discountPrice={row.discount_price}
                          link={`/courses/${row.slug}`}
                        />
                      </div>
                    );
                  })}

                {/* <div className="col-md-6 col-lg-4">
                  <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                    <Link to="#" className="text-decoration-none">
                      <div className="img-wrap position-relative">
                        <img
                          src="/img/certificates/img-1.png"
                          className="card-img-top img-fluid"
                          alt=""
                        />
                        <span className="_badge badge badge-transparent text-uppercase position-absolute bg-danger">
                          <i className="fa-brands fa-gripfire"></i> New
                        </span>
                      </div>
                      <div className="card-body px-0">
                        <div className="d-flex _partner align-items-center">
                          <div className="_partner_icon_wrap">
                            <img
                              src="/img/partner/google.png"
                              className="_partner_icon img-fluid"
                            />
                          </div>
                          <span className="_partner_name">Google</span>
                        </div>

                        <h5 className="card-title">
                          Vote for Miraex Swisscom Startup Challenge 2023
                        </h5>

                        <small className="_highlight d-block">
                          <FontAwesomeIcon
                            icon={faUserTie}
                            className="_highligt_icon"
                          />
                          <span className="_highligt_title">Professional</span>
                        </small>
                        <small className="_category d-block">Web Development</small>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 col-lg-4">
                  <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
                    <Link to="#" className="text-decoration-none">
                      <div className="img-wrap">
                        <img
                          src="/img/certificates/img-4.png"
                          className="card-img-top img-fluid"
                          alt=""
                        />
                      </div>
                      <div className="card-body px-0">
                        <div className="d-flex _partner align-items-center">
                          <div className="_partner_icon_wrap">
                            <img
                              src="/img/partner/google.png"
                              className="_partner_icon img-fluid"
                            />
                          </div>
                          <span className="_partner_name">Google</span>
                        </div>

                        <h5 className="card-title">
                          Vote for Miraex Swisscom Startup Challenge 2023
                        </h5>

                        <small className="_highlight d-block">
                          <FontAwesomeIcon
                            icon={faUserTie}
                            className="_highligt_icon"
                          />
                          <span className="_highligt_title">Professional</span>
                        </small>
                        <small className="_category d-block">Web Development</small>
                      </div>
                    </Link>
                  </div>
                </div> */}

                <div className="col-12 text-center mt-3 d-lg-none">
                  <Link
                    to="/courses"
                    className="btn btn-outline-light theme-btn"
                  >
                    View All
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
