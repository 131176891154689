import { Helmet } from "react-helmet";

import "./styles/WatchCoursePage.style.css";
import { useEffect } from "react";
import AxiosClient from "../../client/AxiosClient";
import { API_GET_QUIZZ_RESULT } from "../../ApiEndPoints";
import { useState } from "react";
import Loader from "../../component/Loader";
import { useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import {
  FilledPrimaryBtn,
  FilledPrimaryBtnLink,
} from "../../component/FilledBtn";
import RawHTML from "../../component/RawHTML";

export default function QuizzResultPage() {
  const [loading, setLoading] = useState(true);
  const [resultData, setResultData] = useState(null);
  const [quesAns, setQuesAns] = useState([]);
  const [quizz, setQuizz] = useState(null);

  const params = useParams();
  const quizz_id = params.quizz_id;
  const course_id = params.course_id;

  useEffect(() => {
    AxiosClient.post(API_GET_QUIZZ_RESULT + quizz_id, {
      course_id: course_id,
    })
      .then((response) => {
        let data = response.data;
        console.log("result response: ", data);

        if (data.success == true) {
          setResultData(data.data);
          setQuesAns(data.data.questions);
          setQuizz(data.data.quiz);

          setLoading(false);
        }
      })
      .catch(() => {})
      .finally(() => {});
  }, []);

  console.log("quizz-result ", resultData);

  return (
    <main>
      <Helmet>
        <title>Quizz result | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <section className="py-0">
        {/* header starts */}

        <div className="watch-course-header bg-lightaccent py-2">
          <div className="container">
            <div class="row align-items-center">
              <div className="col-md-8">
                <h5 className="mb-0">
                  {quizz == null ? <Loader /> : quizz.title.en}
                </h5>
              </div>
              <div className="col-md-4 ">
                <div className="course-progress text-center d-flex align-items-center w-100">
                  <div>
                    {resultData != null && resultData.pass === 0 ? (
                      <span className="badge bg-danger p-2 badge-sm">
                        FAILED
                      </span>
                    ) : (
                      <span className="badge bg-success p-2">PASS</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* header ends */}
      </section>
      <section>
        <div className="container">
          {quesAns.map((item, index) => {
            return (
              <div className="card mb-2 rounded-1" key={`ans-${index}`}>
                <div className="card-body">
                  <h5 className="card-title d-flex">
                    <strong>{index + 1}: </strong>
                    <RawHTML rawHTML={item.qus} />
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {item.isWrong == true ? (
                      <span className="badge badge-danger bg-danger float-end">
                        Wrong
                      </span>
                    ) : (
                      <span className="badge badge-success bg-success float-end">
                        Correct
                      </span>
                    )}
                  </h6>
                  <p className="card-text mt-3">
                    <ol>
                      {item.option.length > 0 &&
                        item.option.map((option, index) => {
                          return (
                            <li className="mb-3">
                              {option.title}{" "}
                              {option.right ? (
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  color="#0056d2"
                                  fontSize={20}
                                />
                              ) : null}
                            </li>
                          );
                        })}
                    </ol>
                  </p>
                </div>
              </div>
            );
          })}

          {resultData != null && resultData.course && (
            <FilledPrimaryBtnLink
              label={"Back to course"}
              link={`/my-courses/watch/${resultData.course.slug}`}
            />
          )}
        </div>
      </section>
    </main>
  );
}

// function MultipleChoiceOptions({
//   choices,
//   handleSaveAnswers,
//   answers,
//   currentQuestionIndex,
// }) {
//   const ans = [];

//   const options = [];

//   return (
//     <div className="multiple-choice-options-wrap">
//       {options.map((item, index) => {
//         return (
//           <label
//             className="d-block"
//             htmlFor={`multiple-form-${index}`}
//             key={`multiple-choice-${index}`}
//           >
//             <input
//               type="checkbox"
//               className="form-check-input"
//               onChange={() => {
//                 handleSaveAnswers();
//               }}
//               value={item.id}
//               checked={item.id === answers[currentQuestionIndex] ? true : false}
//             />

//           </label>
//         );
//       })}
//     </div>
//   );
// }
