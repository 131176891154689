import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";

export default function ContactUsPage() {
  return (
    <main>
      <Helmet>
        <title>Contact Us | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Contact Us"
        desc="Facing any issue? contact to our support"
      />

      <section class="vh-70 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 px-5 d-flex align-items-center">
              <h2 class="h1 fw-500">Learner Support</h2>
            </div>
            <div class="col-md-6 px-5">
              <h4 class="mb-4">
                If you are a learner and need help, please visit our Learner
                Help Center to find troubleshooting and FAQs or contact our
                Learner Support team.
              </h4>
              <h5 class="mb-3">
                You can search for your issue or check out our most popular
                articles:
              </h5>

              <a href="#" class="mt-4 text-decoration-none d-block">
                Check and update your email communication preferences
              </a>

              <a href="#" class="mt-4 text-decoration-none d-block">
                Cancel a subscription
              </a>

              <a href="#" class="mt-4 text-decoration-none d-block">
                Refund policies
              </a>
              <a href="#" class="mt-4 text-decoration-none d-block">
                Troubleshooting login and account issues
              </a>
            </div>
          </div>
        </div>
      </section>

      <section class="bg-lightgrey vh-70 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 px-5">
              <h2 class="h1 fw-500">University Partnership Inquiries</h2>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center px-5">
              <p class="lead fw-500">
                If you are a university interested in creating certificates or
                degrees on our platform, please apply here.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="vh-70 d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-6 d-flex align-items-center px-5">
              <h2 class="h1 fw-500">Privacy Inquiries</h2>
            </div>
            <div class="col-md-6 d-flex align-items-center justify-content-center px-5">
              <p class="lead fw-500">
                If you have questions about our Privacy Notice or an enquiry
                about how we protect your personal information, you can contact
                us at privacy@ialm.academy.
              </p>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
