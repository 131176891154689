import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";
import Loader from "../component/Loader";
import { persistReducer } from "redux-persist/es/persistReducer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import AxiosClient from "../client/AxiosClient";
import { API_EMAIL_VERIFICATION } from "../ApiEndPoints";
import { useState } from "react";
import { FilledPrimaryBtnLink } from "../component/FilledBtn";
import DismissableAlert from "../component/DismissableAlert";

export default function EmailVerificationPage() {
  const params = useParams();

  const [data, setData] = useState(null);
  const [alert, setAlert] = useState({
    hasError: false,
    varient: "",
    msg: "",
  });

  useEffect(() => {
    AxiosClient.post(API_EMAIL_VERIFICATION, {
      token: params.token,
    })
      .then((response) => {
        const responseData = response.data;

        setData(responseData);

        if (responseData.success === false) {
          setAlert({
            hasError: true,
            varient: "danger",
            msg: responseData.msg,
          });
        }
      })
      .catch((error) => {
        setAlert({
          hasError: true,
          varient: "danger",
          msg: error,
        });
      });
  }, []);

  return (
    <main>
      <Helmet>
        <title>Email Verification | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb title="Email verification" desc="" />

      <section>
        <div className="container">
          {alert.hasError && (
            <>
              <DismissableAlert
                title="Error"
                desc={alert.msg}
                varient={alert.varient}
              />

              <div className="text-center">
                <FilledPrimaryBtnLink label={"Back to Home"} link={"/"} />
              </div>
            </>
          )}

          {data == null ? (
            <>
              <h4 className="text-center">
                Verifying... <Loader />
              </h4>
              <p className="lead text-center">
                We are verifying your email, just a moment
              </p>
            </>
          ) : (
            data.success === true && (
              <>
                <h4 className="text-center">Verification successfull</h4>
                <p className="lead text-center">
                  Your email has been verified successfully. Please login
                </p>
                <div className="text-center">
                  <FilledPrimaryBtnLink
                    label={"Proceed to login"}
                    link={"/?action=login"}
                  />
                </div>
              </>
            )
          )}
        </div>
      </section>
    </main>
  );
}
