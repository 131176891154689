import { faGraduationCap, faRupee } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import "./../styles/course_card_tile.style.css";
import NativeLazyImage from "./NativeLazyImage";

export default function CourseCardTile({
  thumbnail,
  instructorName,
  title,
  price,
  discountPrice,
  category,
  link = "#",
}) {
  return (
    <div className="card cc-style-1 mb-3 scale-on-hover shadow-on-hover">
      <Link to={link} className="text-decoration-none">
        <div className="img-wrap position-relative">
          {/* <img src={thumbnail} className="card-img-top img-fluid" alt="" /> */}
          <NativeLazyImage
            src={thumbnail}
            classes="card-img-top img-fluid"
            alt={title}
          />
        </div>
        <div className="card-body px-0">
          <div className="d-flex _partner align-items-center">
            <div className="_partner_icon_wrap text-center">
              <FontAwesomeIcon
                icon={faGraduationCap}
                className="_highligt_icon"
                color="#0056d2"
              />
            </div>
            <span className="_partner_name">By {instructorName}</span>
          </div>

          <h5 className="card-title">{title}</h5>

          <small className="_highlight d-block">
            <FontAwesomeIcon
              icon={faRupee}
              className="_highligt_icon"
              color="#0056d2"
            />
            <span className="_highligt_title">
              {discountPrice != null ? (
                <>
                  <del className="text-muted">{price}</del>{" "}
                  <span className="ms-2">{discountPrice}</span>
                </>
              ) : (
                price
              )}
            </span>
          </small>
          <small className="_category d-block">{category}</small>
        </div>
      </Link>
    </div>
  );
}
