import NativeLazyImage from "./NativeLazyImage";
import "./../styles/testimonial_tile.style.css";

export default function TestimonialTile({ avatar, author, designation, desc }) {
  return (
    <div className="card testimonial-card border-0 px-4 mb-4">
      <div className="avtar mx-auto">
        {/* <img
          src="/img/testimonial/Circle_Kenia.png"
          className="card-img-top"
          alt=""
        /> */}

        <NativeLazyImage
          src={avatar}
          alt={`${author} avatar`}
          classes="card-img-top"
        />
      </div>
      <div className="card-body text-center">
        <h4 className="card-title">{author}</h4>
        <p className="card-city">{designation}</p>
        <span className="sep mx-auto"></span>

        <div className="_desc mt-3">{desc}</div>
      </div>
    </div>
  );
}
