import { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TogglePasswordField from "./TogglePasswordField";
import { Link, useNavigate } from "react-router-dom";

import "./../styles/registration_form_model.style.css";
import Loader from "./Loader";
import { API_REGISTER } from "../ApiEndPoints";
import AxiosClient from "../client/AxiosClient";
import DismissableAlert from "./DismissableAlert";
import { SERVER_ERR_MSG } from "../DefaultMsg";

export default function RegisterationFormModal() {
  const navigateTo = useNavigate();
  const [show, setShow] = useState(true);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    password: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  function setPasswordField(password) {
    setFormValues({ ...formValues, password: password });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setShowLoader(true);
    setshowAlert({
      show: false,
    });

    console.log({ ...formValues });

    AxiosClient.post(API_REGISTER, formValues)
      .then((response) => {
        const { data: responseData } = response;

        if (responseData.success === true) {
          setFormErrors({
            name: "",
            email: "",
            mobile: "",
            password: "",
          });

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.message,
          });

          setTimeout(() => {
            navigateTo("/?action=login");
          }, 4000);
        } else {
          setFormErrors({
            name:
              responseData.errors.name != null ? responseData.errors.name : "",
            email:
              responseData.errors.email != null
                ? responseData.errors.email
                : "",
            mobile:
              responseData.errors.mobile != null
                ? responseData.errors.mobile
                : "",
            password:
              responseData.errors.password != null
                ? responseData.errors.password
                : "",
          });

          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.message,
          });

          console.log(showAlert);
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: SERVER_ERR_MSG,
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  return (
    <>
      <Modal
        className="login-modal"
        show={show}
        onHide={() => {
          navigateTo("/");
        }}
        centered
      >
        <Modal.Header className="border-0 pt-4 text-center" closeButton>
          <Modal.Title className="w-100">Register</Modal.Title>
        </Modal.Header>
        <p className="text-center px-5">
          Learn on your own time from top universities and businesses.
        </p>

        <Modal.Body>
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Form.Group className="mb-3" controlId="your_name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Your name"
                value={formValues.name}
                onChange={(e) => {
                  setFormValues({ ...formValues, name: e.target.value });
                }}
              />
              <span className="validation-err text-danger">
                {formErrors.name}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="your_email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                placeholder="yourmail@example.com"
                value={formValues.email}
                onChange={(e) => {
                  setFormValues({ ...formValues, email: e.target.value });
                }}
              />
              <span className="validation-err text-danger">
                {formErrors.email}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="your_phone">
              <Form.Label>Mobile no.</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                value={formValues.mobile}
                onChange={(e) => {
                  setFormValues({ ...formValues, mobile: e.target.value });
                }}
              />
              <span className="validation-err text-danger">
                {formErrors.mobile}
              </span>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Password</Form.Label>
              <TogglePasswordField
                password={formValues.password}
                setPassword={setPasswordField}
              />
              <span className="validation-err text-danger">
                {formErrors.password}
              </span>
            </Form.Group>

            {showAlert.show === true && (
              <DismissableAlert
                varient={showAlert.type}
                title={showAlert.title}
                desc={showAlert.desc}
              />
            )}

            <Button
              type="submit"
              className="btn btn-primary theme-btn bg-primary d-block w-100 mt-5"
              disabled={showLoader ? true : false}
            >
              <span className="me-2">Register</span>
              {showLoader && <Loader classes="text-white" />}
            </Button>

            <p className="d-flex justify-content-center flex-wrap mt-4 text-sm">
              <span className="text-muted">Already have an account?</span>
              <Button
                type="button"
                variant="transparent"
                className="p-0 m-0 ms-1 text-decoration-underline link"
                onClick={() => navigateTo("/?action=login")}
              >
                Login
              </Button>
            </p>

            <hr className="my-4" />
            <p className="d-flex justify-content-center flex-wrap mt-3 text-sm">
              <span className="text-muted">
                Having trouble in registration?
              </span>
              <Button
                type="button"
                variant="transparent"
                className="p-0 m-0 ms-1 text-decoration-underline link"
              >
                Visit help center
              </Button>
            </p>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
