import { memo, useState } from "react";
import Alert from "react-bootstrap/Alert";

function DismissableAlert({
  varient = "danger",
  title = "Error",
  desc,
  classes = "",
}) {
  return (
    <Alert className={`py-2 ${classes}`} variant={varient}>
      {title.trim() != "" && <Alert.Heading>{title}</Alert.Heading>}
      <p className="mb-0">{desc}</p>
    </Alert>
  );
}

export default DismissableAlert;
