import React, { useEffect } from "react";
import BreadCrumb from "../component/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../redux/slice/UserSessionSlice";
import AxiosClient from "../client/AxiosClient";
import { API_GET_PROFILE } from "../ApiEndPoints";

export default function AdminLoginPage() {
  const params = useParams();
  let token = params.token;
  const navigateTo = useNavigate();

  const dispatch = useDispatch();
  useEffect(() => {
    localStorage.setItem("token", token);

    AxiosClient.get(API_GET_PROFILE).then((response) => {
      let responseData = response.data;

      localStorage.setItem("login_name", responseData.data.name);
      localStorage.setItem("login_email", responseData.data.email);
      localStorage.setItem("login_phone", responseData.data.phone);
      localStorage.setItem("avatar", responseData.data.avatar);

      dispatch(
        login({
          name: responseData.data.name,
          email: responseData.data.email,
          token: token,
          avatar: responseData.data.avatar,
        })
      );

      navigateTo("/");
    });
  });

  return (
    <main>
      <section
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh" }}
      >
        <h2>Please wait </h2>
      </section>
    </main>
  );
}
