import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";

import React, { useEffect, useState } from "react";
import AxiosClient from "../../client/AxiosClient";
import { API_GET_CATEGORY, API_GET_CATEGORY_COURSES } from "../../ApiEndPoints";

import { useParams } from "react-router-dom";

import InfiniteScroll from "react-infinite-scroll-component";
import AllCourses from "../../sections/AllCourses";
import Loader from "../../component/Loader";
import ErrorBoundaryWrap from "../../error-boundries/ErrorBoundaryWrap";
import ErrorBoundryWrapParent from "../../error-boundries/ErrorBoundryWrapParent";

export default function CategoryCoursesPage() {
  const [categoryLoading, setCategoryLoading] = useState(true);
  const [category, setCategory] = useState(null);

  const params = useParams();

  // getting category first
  useEffect(() => {
    AxiosClient.get(`${API_GET_CATEGORY}${params.slug}`)
      .then((response) => {
        const categoryData = response.data.data;
        console.log(categoryData);
        setCategory(categoryData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setCategoryLoading(false);
      });
  }, [params.slug]);

  // now getting courses of this category
  const [courses, setCourses] = useState([]); // Data fetched from the server
  const [page, setPage] = useState(1); // Current page number
  const [hasMore, setHasMore] = useState(true); // Whether there is more data to load
  const [courseLoading, setCourseLoading] = useState(true);

  const limit = process.env.REACT_APP_PAGINATION_LIMIT || 12;

  const fetchData = async () => {
    try {
      const response = await AxiosClient.get(
        API_GET_CATEGORY_COURSES + params.slug,
        {
          params: { page: page, limit: limit },
        }
      );

      console.log("pagination resposne", response.data.data);
      const newItems = response.data.data.items; // Assuming API returns { items: [], total: number }
      const total = response.data.data.total;

      // Append new items to the existing list
      setCourses((prevItems) => [...prevItems, ...newItems]);

      // Increment page
      if (newItems.length > 0) {
        setPage((prevPage) => prevPage + 1);
      }

      // Check if there are more items to load
      if (courses.length + newItems.length >= total) {
        setHasMore(false);
      }

      setCourseLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    setCourseLoading(true);
    fetchData();
    setPage(1);
    setCourses([]);
  }, [params.slug]);

  return (
    <main>
      <Helmet>
        <title>
          {category == null ? "Category" : category.name} |{" "}
          {process.env.REACT_APP_NAME}
        </title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <ErrorBoundryWrapParent>
        <BreadCrumb
          title={category == null ? "Loading ..." : category.name}
          desc={`Browse our extensive range of ${
            category == null ? "law courses" : category.name
          }, carefully designed to enhance your legal knowledge and skills for a successful career in the legal field`}
        />
      </ErrorBoundryWrapParent>

      <section>
        <div className="container">
          {!courseLoading && (
            <InfiniteScroll
              className="inifit-scroll__wrap"
              dataLength={courses.length} // This is important to track the length of the list
              next={fetchData} // Function to fetch the next batch of data
              hasMore={hasMore} // Whether more data should be loaded
              loader={
                <div className="text-center">
                  <Loader />
                </div>
              } // Loader component
              endMessage={
                <p className="text-center mt-3">
                  <b className="fw-500 bg-light p-2  border border-primary rounded-2">
                    No more course found! Check back later for more updates.
                  </b>
                </p>
              }
            >
              <AllCourses courses={courses} />
            </InfiniteScroll>
          )}

          {courseLoading && (
            <div className="text-center">
              <Loader />
            </div>
          )}
        </div>
      </section>
    </main>
  );
}
