import { Helmet } from "react-helmet";
import BreadCrumb from "../../component/BreadCrumb";
import UnderlineTitle from "../../component/UnderlineTitle";
import BlogCategories from "../../component/BlogCategories";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "../../component/Loader";
import AxiosClient from "../../client/AxiosClient";
import { API_GET_RECENT_BLOGS } from "../../ApiEndPoints";
import BlogGridSection from "../../sections/BlogGridSection";
import { useEffect } from "react";

export default function BlogPage() {
  // getting recent blogs with pagination starts

  // now getting courses of this category
  const [recentBlogs, setRecentBlogs] = useState([]); // Data fetched from the server
  const [page, setPage] = useState(1); // Current page number
  const [hasMore, setHasMore] = useState(true); // Whether there is more data to load
  const [loading, setLoading] = useState(true);

  const limit = process.env.REACT_APP_PAGINATION_LIMIT || 12;

  const fetchData = async () => {
    try {
      const response = await AxiosClient.get(API_GET_RECENT_BLOGS, {
        params: { page: page, limit: limit },
      });

      console.log("pagination resposne", response.data.data);
      const newItems = response.data.data.items; // Assuming API returns { items: [], total: number }
      const total = response.data.data.total;

      // Append new items to the existing list
      setRecentBlogs((prevItems) => [...prevItems, ...newItems]);

      // Increment page
      if (newItems.length > 0) {
        setPage((prevPage) => prevPage + 1);
      }

      // Check if there are more items to load
      if (recentBlogs.length + newItems.length >= total) {
        setHasMore(false);
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // getting recent blogs with pagination ends

  return (
    <main>
      <Helmet>
        <title>Blogs | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="Blogs"
        desc="Enrich your knowelge and always stay updated with our blog"
      />

      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-8">
              {!loading && (
                <InfiniteScroll
                  className="inifit-scroll__wrap"
                  dataLength={recentBlogs.length} // This is important to track the length of the list
                  next={fetchData} // Function to fetch the next batch of data
                  hasMore={hasMore} // Whether more data should be loaded
                  loader={
                    <div className="text-center">
                      <Loader />
                    </div>
                  } // Loader component
                  endMessage={
                    <p className="text-center mt-3">
                      <b className="fw-500 bg-light p-2  border border-primary rounded-2">
                        We are uploading more blogs
                      </b>
                    </p>
                  }
                >
                  <BlogGridSection blogs={recentBlogs} title={"Recent Blogs"} />
                </InfiniteScroll>
              )}
            </div>
            <div className="col-md-4">
              <BlogCategories />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
