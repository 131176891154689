import React from "react";

export default function BreadCrumb({ title = " ", desc = "" }) {
  return (
    <div className=" py-4 bg-light">
      <div className="container">
        <h1>{title}</h1>
        <p className="lead">{desc}</p>
      </div>
    </div>
  );
}
