import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ImageCard from "../../component/ImageCard";
import { useEffect } from "react";
import { Axios } from "axios";
import { API_ALL_INSTRUCTORS } from "../../ApiEndPoints";
import AxiosClient from "../../client/AxiosClient";
import { useState } from "react";
import Loader from "../../component/Loader";

export default function AllFacultiesPage() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_ALL_INSTRUCTORS)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.error("Error while fetching faculties: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <main>
      <Helmet>
        <title>All Faculties | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="All Instructors"
        desc="Explore our dedicated team of instructors, each bringing their unique expertise to help you succeed"
      />

      <section>
        <div className="container">
          <div className="row">
            {loading && (
              <div className="col-12 text-center">
                <Loader classes="text-primary" />
              </div>
            )}

            {!loading &&
              data.map((row, index) => {
                return (
                  <div
                    className="col-md-4 px-4 mb-3"
                    key={`faculties-${index}`}
                  >
                    <ImageCard
                      thumbnail={row.image}
                      title={row.instructor_name}
                      institute={row.job_title == null ? "-" : row.job_title}
                      link={`/faculties/${row.username}`}
                    />
                  </div>
                );
              })}
            {/* <div className="col-md-4 px-4">
              <ImageCard
                thumbnail="/img/instructors/instructor-1.jpg"
                title={"Central TB Division"}
                institute={
                  "Central TB Division, Ministry Of Health And Family Welfare"
                }
              />
            </div> */}
          </div>
        </div>
      </section>

      {/* <ErrorBoundaryWrap
        component={Hero2}
        title="Be A Part of Our Faculty Program"
        desc={`Opportunities for the Industry experts to be a part of our Faculty
              Program ${REACT_APP_FULL_NAME} (${REACT_APP_ABBR_NAME}) has launched
              Faculty Enrollment Programme.`}
        rightBannerCss={{
          background: "url(/img/man-pointing-on-book.png)",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom center",
        }}
        btnLabel="Apply now"
        link="#faculty-application-form"
        handler={() => referToSection(facultyRegSecRef)}
      />

      <section className="">
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <NativeLazyImage
                src={`/img/mentor-teaching-class.png`}
                alt="Mentor teachign in classs"
                classes="img-fluid px-3"
              />
            </div>

            <div className="col-md-6 d-flex justify-content-center flex-column">
              <h2 className="">Faculty Enrollment Programme</h2>
              <p className="mb-0">
                The Indian Academy of Law and Management (IALM) has launched
                Faculty Enrollment Programme. This Programme will provide a
                unique and huge opportunity for respective faculties to engage
                and get united with IALM. This Programme is being organized to
                give hike students' knowledge. We strongly believe a good
                Faculty is like a candle, it consumes itself to light the way
                for others and Faculty is the one profession that creates all
                other professions. IALM strongly believe that the Indian student
                community will gain enormously in their profession and careers
                with excellent faculty. We are sure that your valued experience
                in this field will improve our student's intelligence and
                develop their knowledge and their career path.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-lightaccent position-relative z-1">
        <div className="container">
          <div className="row ">
            <div className="col-md-6">
              <h2 className="">How to Design your modules?</h2>
              <p className="mb-3">
                You start with your passion and knowledge. Then choose a
                promising topic. It's important to make sure your course is
                engaging for your studimport NativeLazyImage from
                './../component/Nativeimport AxiosClient from './../../client/AxiosClient';
LazyImage'; ents with a well-structured,
                practical, and satisfying learning experience. The way that you
                teach — what you bring to it — is up to you. We offer plenty of
                resources on how to create your first course. And, our team
                helps keep you organized.
              </p>

              <ol className="list-group list-group-flush mb-5">
                <li className="list-group-item bg-transparent">
                  <strong>1. Choose your topic: </strong>Choose something you're
                  proficient in and genuinely excited about teaching
                </li>

                <li className="list-group-item bg-transparent">
                  <strong>2. Document Your Course Content:</strong>Create
                  content that is easily accessible and portable on any device.
                  Our support team is available to help you throughout the
                  process and provide feedback on test videos.
                </li>

                <li className="list-group-item bg-transparent">
                  <strong>3. Write your Course Arrival Page:</strong>Even if
                  students find your course through natural search, IALM
                  promotions, or through your IALM marketing efforts, they'll
                  ultimately land on your Course arrival page (CAP). This is
                  where students decide whether your course is worth enrolling
                  in by reviewing your title, course description, preview
                  lectures, etc.
                </li>

                <li className="list-group-item bg-transparent">
                  <strong>4. First Rating:</strong> Gather your first ratings
                  and reviews by promoting your course through social media and
                  your professional networks.
                </li>
                <li className="list-group-item bg-transparent">
                  <strong>5. Our promotions drive traffic to courses:</strong>{" "}
                  There’s even more opportunity for courses chosen for{" "}
                  {REACT_APP_ABBR_NAME} Business.
                </li>
              </ol>
            </div>

            <div className="col-md-6 d-flex align-items-center">
              <img
                src="/img/faculty-teaching-onboard.png"
                className="img-fluid px-3"
              />
            </div>
          </div>
        </div>

        <section className="py-0 instructor-benefits">
          <div className="container">
            <div className="bg-black p-4 rounded-3">
              <h2 className="text-white text-center mb-3">
                Benefits for Faculty
              </h2>
              <span className="badge rounded-pill bg-white bg-white text-dark">
                Gain access to learners around the world
              </span>
              <span className="badge rounded-pill bg-white text-dark">
                Own your content
              </span>
              <span className="badge rounded-pill bg-white text-dark ">
                Teach your way
              </span>
              <span className="badge rounded-pill bg-white text-dark">
                Expand your earning potential
              </span>
              <span className="badge rounded-pill bg-white text-dark">
                Be a part of a thriving community
              </span>

              <span className="badge rounded-pill bg-white text-dark">
                Your course will be discoverable on our website where you earn
                revenue from each paid enrollment.
              </span>
            </div>
          </div>
        </section>
      </section>

      <section className="pt-2x bg-primary faculty-benefits">
        <div className="container pt-5">
          <div className="row">
            <div className="col-md-6 pe-5">
              <div className="position-sticky top-50p">
                <h2 className="text-white">
                  How IALM marketing benefits you ?
                </h2>
                <p className="text-white lead">
                  Discover how IALM's marketing empowers your faculty institute
                  program with enhanced visibility and outreach. Maximize impact
                  and engagement through tailored promotional strategies."
                </p>

                <FilledWhiteBtn
                  label="Apply now"
                  classes="mt-3 text-white"
                  clickHandler={() => referToSection(facultyRegSecRef)}
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/advertizing-on-sites.svg"
                    title="Seasonal Campaign"
                    desc="Boost your course visibility with targeted seasonal promotions."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/campaign.svg"
                    title="Advertising on Popular Sites"
                    desc="Reach a wider audience by showcasing your course on
                      high-traffic platforms."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/tap-on-deep.svg"
                    title="Leverage Our Student Insights"
                    desc="Utilize our expertise in student interests to create courses that truly resonate."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/social-media-marketing.svg"
                    title="Social Media Marketing"
                    desc="Promote your courses effectively and reach your target audience on popular social platforms."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/workshop-events.svg"
                    title="Organizing Workshops & Events"
                    desc="Engage learners with interactive workshops and build lasting connections."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/webinar.svg"
                    title="Engaging Students Through Webinars"
                    desc="Host impactful webinars to connect with students and foster meaningful interactions."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/sales-growth.svg"
                    title="Growing your course sales"
                    desc="Host impactful webinars to connect with students and foster meaningful interactions."
                  />
                </div>

                <div className="col-md-6 px-3">
                  <LeftIconCard
                    iconPath="/img/instructor-benefits/reputation-mangement.svg"
                    title="Building a Strong Market Reputation"
                    desc="Establish credibility and trust to stand out in a competitive landscape."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faculty-application-form" ref={facultyRegSecRef}>
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              <h2 className="fw-500">
                Ready to becoming a Faculty? Create your profile & tell us about
                yourself.
              </h2>
            </div>
            <div className="col-md-7">
              <ErrorBoundaryWrap component={FacultyRegistrationForm} />
            </div>
          </div>
        </div>
      </section> */}
    </main>
  );
}
