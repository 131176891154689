import React from "react";
import { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AxiosClient from "../../../client/AxiosClient";
import Loader from "../../../component/Loader";
import RawHTML from "../../../component/RawHTML";
import { API_GET_META_DATA } from "../../../ApiEndPoints";
import { useEffect } from "react";
import NativeLazyImage from "../../../component/NativeLazyImage";
import ReviewCard from "../../../component/ReviewCard";

export default function WatchCourseAdditionalDetails({ course_id }) {
  const [key, setKey] = useState("overview");

  // overview starts

  const [overviewLoading, setOverviewLoading] = useState(true);
  const [overviewData, setOverviewData] = useState(null);

  useEffect(() => {
    AxiosClient.post(API_GET_META_DATA, {
      course_id: course_id,
      type: "overview",
    })
      .then((response) => {
        const data = response.data;
        if (data.success == true) {
          setOverviewData(data.data);
          setOverviewLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error while fetching overview: ", err);
      });
  }, []);

  // overview ends

  // instructor starts

  const [instructorLoading, setinstructorLoading] = useState(true);
  const [instructorData, setInstructorData] = useState(null);

  useEffect(() => {
    AxiosClient.post(API_GET_META_DATA, {
      type: "instructor",
      course_id: course_id,
    })
      .then((response) => {
        const data = response.data;
        if (data.success == true) {
          setInstructorData(data.data);
          setinstructorLoading(false);
        }
      })
      .catch((err) => {
        console.error("Error while fetching overview: ", err);
      });
  }, []);

  // instrucor ends

  // files starts

  const [filesLoading, setFilesLoading] = useState(true);
  const [filesData, setFilesData] = useState([]);

  useEffect(() => {
    AxiosClient.post(API_GET_META_DATA, {
      type: "additional_attachments",
      course_id: course_id,
    })
      .then((response) => {
        const data = response.data;
        if (data.success == true) {
          setFilesData(data.data);
          setFilesLoading(false);

          console.log("files data, ", data.data);
        }
      })
      .catch((err) => {
        console.error("Error while fetching additional files: ", err);
      });
  }, []);

  // files ends

  // reviews starts

  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviewsData, setReviewsData] = useState([]);

  useEffect(() => {
    AxiosClient.post(API_GET_META_DATA, {
      type: "reviews",
      course_id: course_id,
    })
      .then((response) => {
        const data = response.data;
        if (data.success == true) {
          setReviewsData(data.data);
          setReviewsLoading(false);

          console.log("reviews data, ", data.data);
        }
      })
      .catch((err) => {
        console.error("Error while fetching reviews: ", err);
      });
  }, []);

  // reviews ends

  return (
    <div className="watch-course-additional-details fw-500 mt-5">
      <Tabs id="" activeKey={key} onSelect={(k) => setKey(k)} className="mb-3">
        <Tab eventKey="overview" title="Overview">
          {overviewLoading && <Loader />}
          {!overviewLoading && overviewData != null && (
            <div>
              <RawHTML rawHTML={overviewData.about} />
            </div>
          )}
        </Tab>
        <Tab eventKey="about-instructor" title="About Instructor">
          {instructorLoading && <Loader />}
          {!instructorLoading && instructorData != null && (
            <div>
              <div class="card rouded-1 border-none">
                <span className="" style={{ width: "70px", height: "70px" }}>
                  <NativeLazyImage
                    src={instructorData.image}
                    alt={instructorData.instructor_name}
                    classes="rounded-circle"
                  />
                </span>

                <div class="card-body">
                  <h5 class="card-title">{instructorData.instructor_name}</h5>
                  <p class="card-text">
                    <RawHTML rawHTML={instructorData.about} />
                  </p>
                </div>
              </div>
            </div>
          )}
        </Tab>
        <Tab eventKey="additional-attachements" title="Additional Attachments">
          <h4>Download additional attachment files from here</h4>
          {filesLoading && <Loader />}
          {!filesLoading && filesLoading.length == 0 && (
            <p>No additional attachment added yet!</p>
          )}
          {!filesLoading && filesData.length > 0 && (
            <ol className="list-group list-group-flush">
              {filesData.map((item, index) => {
                return (
                  <li key={`files-${index}`} className="list-group-item">
                    <a
                      href={item.file}
                      className="text-decoration-none fw-400"
                      target="_blank"
                      download
                    >
                      {item.filename}
                    </a>
                  </li>
                );
              })}
            </ol>
          )}
        </Tab>
        <Tab eventKey="reviews" title="Reviews">
          {reviewsLoading && <Loader />}
          {!reviewsLoading && reviewsData.length == 0 && (
            <p>No reviews found! Be the first to write one</p>
          )}

          {!reviewsLoading &&
            reviewsData.map((item, index) => {
              return (
                <ReviewCard
                  key={`review-${index}`}
                  avatar={item.user.image}
                  rating={item.star}
                  name={item.user.name}
                  review={item.comment}
                />
              );
            })}
        </Tab>
      </Tabs>
    </div>
  );
}
