import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import AllCourses from "../../sections/AllCourses";

import React, { useEffect, useState } from "react";
import AxiosClient from "../../client/AxiosClient";
import { API_ALL_COURSES_WITH_CATEGORIES } from "../../ApiEndPoints";
import LabeledTwoThumbs from "../../component/PriceRangeSlider";
import PriceRangeSlider from "../../component/PriceRangeSlider";
import Loader from "../../component/Loader";
import { Link } from "react-router-dom";

export default function AllCoursesPage() {
  const [loading, setLoading] = useState(true);
  const [courses, setCourses] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_ALL_COURSES_WITH_CATEGORIES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setCourses(listData);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <main>
      <Helmet>
        <title>All Courses | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="All Courses"
        desc="Browse our extensive range of law courses, carefully designed to enhance your legal knowledge and skills for a successful career in the legal field"
      />

      <section className="all-courses-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="table-responsive">
                <table className="table table-striped text-center">
                  <thead>
                    <tr>
                      <th className="bg-dark text-white fw-500">Category</th>
                      <th className="bg-dark text-white fw-500">
                        Sub Category
                      </th>
                      <th className="bg-dark text-white fw-500">Course name</th>
                      <th className="bg-dark text-white fw-500">Duration</th>
                      <th className="bg-dark text-white fw-500">Faculty</th>
                      <th className="bg-dark text-white fw-500">
                        Course Fees (INR)
                      </th>
                      <th className="bg-dark text-white ">More details</th>
                      <th className="bg-dark text-white fw-500">
                        Attributes/Keywords
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading && (
                      <tr>
                        <td colspan="10" className="text-center">
                          <Loader classes="text-primary" />
                        </td>
                      </tr>
                    )}

                    {!loading && courses.length === 0 && (
                      <tr>
                        <td colspan="10" className="text-center">
                          <Loader classes="text-primary" />
                        </td>
                      </tr>
                    )}

                    {!loading &&
                      courses.length > 0 &&
                      courses.map((item, itemIndex) => (
                        <React.Fragment key={itemIndex}>
                          {item.courses.length > 0 && (
                            <tr>
                              <td className="strong fw-500">{item.name}</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          )}
                          {item.courses.map((row, rowIndex) => (
                            <tr key={`${itemIndex}-${rowIndex}`}>
                              <td></td>
                              <td>
                                {row.sub_category_name !== null &&
                                row.sub_category_name !== ""
                                  ? JSON.parse(row.sub_category_name).en
                                  : "-"}
                              </td>
                              <td>{row.title}</td>
                              <td>{row.access_limit} Days</td>
                              <td>{row.instructor_name}</td>
                              <td>
                                {row.discount_price != null &&
                                row.discount_price != "" &&
                                row.discount_price != 0
                                  ? row.discount_price
                                  : row.price}
                              </td>
                              <td>
                                <Link to={`/courses/${row.slug}`}>
                                  More details
                                </Link>
                              </td>
                              <td>{row.tags}</td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
