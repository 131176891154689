import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

export default function LogInGuard({ children }) {
  //   const [authorized, setAuthorized] = useState(null);

  const userSession = useSelector((state) => state.userSession);

  const isAuthorized = Object.keys(userSession).length > 0 ? true : false;

  return isAuthorized ? children : <Navigate to={"/?action=login"} />;
}
