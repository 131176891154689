import React from "react";
import "./../styles/cart.style.css";
import NativeLazyImage from "./NativeLazyImage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faIndianRupee, faRupee } from "@fortawesome/free-solid-svg-icons";
import { FilledPrimaryBtnLink } from "./FilledBtn";
import { useSelector } from "react-redux";

export default function Cart({ childl, ...rest }) {
  const cart = useSelector((state) => state.cart);

  console.log("Cart state: ", cart);

  let totalMrp = 0;
  let paybleAmount = 0;

  return (
    <div id="cart" {...rest}>
      <div className="cart-items-wrap">
        {cart.length == 0 && (
          <h5 className="text-center p-2">No items in the cart</h5>
        )}

        {cart.length > 0 &&
          cart.map((item, index) => {
            totalMrp += item.mrpPrice;
            paybleAmount +=
              item.discountPrice == null || item.discountPrice == ""
                ? item.mrpPrice
                : item.discountPrice;
            return (
              <div
                className="cart-item d-flex border-bottom mb-3 py-2"
                key={`cart-item-${index}`}
              >
                <span className="_img_wrap me-2">
                  <NativeLazyImage src={item.img} className="img-fluid" />
                </span>
                <div className="_content">
                  <p className="_item_title mb-0">{item.name}</p>
                  <p className="_by small muted mb-1  ">
                    by <ins>{item.instructorName}</ins>
                  </p>
                  <p className="price mb-0">
                    <FontAwesomeIcon
                      icon={faIndianRupee}
                      fontSize={15}
                      className="me-1"
                    />

                    <strong className="me-1">
                      {item.discountPrice == null ||
                      item.discountPrice == "" ||
                      item.discountPrice == 0
                        ? item.mrpPrice
                        : item.discountPrice}
                    </strong>
                    {item.discountPrice == null &&
                      item.discountPrice != 0 &&
                      item.mrpPrice != null &&
                      item.mrpPrice != "" && <del>{item.mrpPrice}</del>}
                  </p>
                </div>
              </div>
            );
          })}

        {/* <div className="cart-item d-flex border-bottom mb-3">
          <span className="_img_wrap me-2">
            <NativeLazyImage
              src="https://img-c.udemycdn.com/course/100x100/341472_b57e_3.jpg"
              className="img-fluid"
            />
          </span>
          <div className="_content">
            <p className="_item_title mb-0">
              Master certification on corporate contracts
            </p>
            <p className="_by small muted mb-1  ">by Sudeep Chaudhary</p>
            <p className="price mb-0"><FontAwesomeIcon icon={faIndianRupee} fontSize={15} className="me-1"/><strong className="me-1">599</strong><del>599</del></p>
          </div>
        </div> */}

        {cart.length > 0 && (
          <div className="_final d-flex align-items-center">
            <h5 className="me-2 mb-0 p-2">Total: </h5>{" "}
            <FontAwesomeIcon
              icon={faIndianRupee}
              fontSize={18}
              className="me-1"
            />
            <h5 className="mb-0">
              {paybleAmount}
              {/* {totalMrp != 0 && <del className="ms-1 text-muted">{totalMrp}</del>} */}
            </h5>
          </div>
        )}
        <FilledPrimaryBtnLink
          classes="w-100 mt-2"
          label={"Go to Cart"}
          link={"/cart"}
        />
      </div>
    </div>
  );
}
