import {
  fab,
  faFacebook,
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faSquareInstagram,
  faTiktok,
  faTwitter,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import {
  faCopy,
  faCopyright,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import NewsletterSection from "./NewsletterSection";
import FooterQuickLinks from "../component/FooterQuickLinks";

export default function Footer() {
  return (
    <footer className="text-center text-md-start">
      <div className="container">
        <NewsletterSection />
        <div className="row">
          <div className="col-md-3">
            <h5 className="mb-0">Quick Links</h5>
            <div className="list-group non-style mb-4 mb-md-0">
              <Link
                href="/blogs"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                Blog
              </Link>
              <Link href="#" className="list-group-item list-group-item-action">
                UPSC Syllabus 2022-2023
              </Link>
              <Link href="/" className="list-group-item list-group-item-action">
                FAQs
              </Link>
              <Link
                to={"/faculties/"}
                className="list-group-item list-group-item-action"
              >
                All Faculties
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IALM Instituional Collaborations
              </Link>
              <Link
                to="/faculty-registration"
                className="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                Course Developer/Part-time Faculty
              </Link>

              <FooterQuickLinks />
            </div>
          </div>
          <div className="col-md-3">
            <h5 className="mb-0">Policies & Contact</h5>
            <div className="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                Refund policy
              </Link>
              <Link
                to="/terms"
                className="list-group-item list-group-item-action"
              >
                Terms Of Use
              </Link>
              <Link
                to="/privacy-policy"
                className="list-group-item list-group-item-action"
              >
                Privacy Policy
              </Link>
              <Link
                to="/contact-us"
                className="list-group-item list-group-item-action"
              >
                Contact us
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IPR and Innovation Cell
              </Link>
            </div>
          </div>

          <div className="col-md-3">
            <h5 className="mb-0">Policies & Contact</h5>
            <div className="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                Refund policy
              </Link>
              <Link
                to="/terms"
                className="list-group-item list-group-item-action"
              >
                Terms Of Use
              </Link>
              <Link
                to="/privacy-policy"
                className="list-group-item list-group-item-action"
              >
                Privacy Policy
              </Link>
              <Link
                to="/contact-us"
                className="list-group-item list-group-item-action"
              >
                Contact us
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                IPR and Innovation Cell
              </Link>
            </div>
          </div>

          <div className="col-md-3">
            <h5 className="mb-0">Contact us</h5>
            <div className="list-group non-style mb-4 mb-md-0">
              <Link
                href="#"
                className="list-group-item list-group-item-action"
                aria-current="true"
              >
                81, National Park, Lajpat Nagar-IV, New Delhi, Delhi 110024,
                India
              </Link>
              <Link href="#" className="list-group-item list-group-item-action">
                Call: +91 96507 27918
              </Link>
              <Link href="#" className="list-group-item list-group-item-action">
                TollFree: 18002586884
              </Link>
              <Link href="#" className="list-group-item list-group-item-action">
                WHATSAPP NUMBER : +91 9650727918
              </Link>
              <Link
                className="list-group-item list-group-item-action"
                aria-disabled="true"
              >
                registrar@ialm.academy
              </Link>
            </div>
          </div>

          <div className="col-12 border-op">
            <div className="footer-bottom d-flex flex-column-reverse flex-md-row justify-content-between mt-5 border-top border-1 pt-3 pt-md-5">
              <div className="mt-3 mt-md-0">
                <p className="text-center text-md-left">
                  &copy; 2024 IALM. All rights reserved.
                </p>
              </div>

              <div className="d-flex justify-content-center justify-content-md-start">
                <a
                  href="https://twitter.com/IndiaAcademyLaw"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faTwitter} className="" />
                </a>

                <a
                  href="https://www.linkedin.com/company/indian-academy-of-law-&-management/?viewAsMember=true&original_referer=http%3A%2F%2Fialm.paybevdev.com%2F"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faLinkedinIn} className="" />
                </a>

                <a
                  href="https://www.facebook.com/people/IALM/100091902900758/"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faFacebookF} className="" />
                </a>

                <a
                  href="https://www.instagram.com/ialm.academy"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faSquareInstagram} className="" />
                </a>

                <a
                  href="mailto:registrar@ialm.academy"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faEnvelope} className="" />
                </a>
                <a
                  href="https://www.youtube.com/c/IALMcampus"
                  target="_blank"
                  className="social-icons text-decoration-none mx-2"
                  rel="noreferrer noopener"
                >
                  <FontAwesomeIcon icon={faYoutube} className="" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
