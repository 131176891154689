import { Link, NavLink } from "react-router-dom";
import "./../styles/desktop_nav.style.css";
import Searchbar from "./Searchbar";

import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { useEffect, useState } from "react";

import AxiosClient from "./../client/AxiosClient";
import { API_ALL_CATEGORIES } from "./../ApiEndPoints";

import Loader from "./../component/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import Cart from "./Cart";
import { useSelector } from "react-redux";
import ProfileDropdown from "./ProfileDropdown";

export default function DesktopNav() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    AxiosClient.get(API_ALL_CATEGORIES)
      .then((response) => {
        setData(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const linkClasses = "nav-link";

  let cart = useSelector((state)=>state.cart);
  let userSession = useSelector((state)=>state.userSession);

  console.log('usersession: ',userSession);

  return (
    <>
      {/* <!-- lg navbar starts --> */}
      <nav className="navbar navbar-expand-lg navbar-light bg-white position-sticky d-none d-xl-block">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src="/logo.png"
              alt="logo"
              className="img-fluid"
              id="header-logo"
              width="60"
            />
          </Link>
          <div className="collapse navbar-collapse" id="navbarScroll">
            <div className="d-flex">
              <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll align-items-center">
                <li className="nav-item dropdown ">
                  <DropdownButton
                    align="end"
                    title="Categories"
                    id="dropdown-menu-align-end"
                    className="btn border border-1 border-primary me-2 bg-transparant p-1 px-2 "
                    variant="transparent"
                  >
                    <div className="bg-lightaccent">
                      {loading === true ? (
                        <div className="text-center">
                          <Loader classes="text-primary" />
                        </div>
                      ) : (
                        data.map((row, index) => {
                          return (
                            <Dropdown.Item key={`desktop-nav-${index}`}>
                              <NavLink
                                to={`category/${row.url}`}
                                className={({ isActive }) =>
                                  isActive
                                    ? `active ${linkClasses}`
                                    : `${linkClasses}`
                                }
                              >
                                {row.name}
                              </NavLink>
                            </Dropdown.Item>
                          );
                        })
                      )}

                      {/* <Dropdown.Item eventKey="2">
                        Patent Agent Examination
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="3">RTI</Dropdown.Item>
                      <Dropdown.Item eventKey="4">RTI</Dropdown.Item>
                      <Dropdown.Item eventKey="5">
                        Professional Politician
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="6">CLAT PG-LLM</Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item eventKey="4">Separated link</Dropdown.Item> */}
                    </div>
                  </DropdownButton>
                </li>
              </ul>

              {/* <!-- search bar starts --> */}
              <Searchbar />
              {/* <!-- search bar ends --> */}
            </div>
          </div>

          {/* <!-- right menu starts --> */}

          <ul className="navbar-nav me-auto mb-2 mb-lg-0 _right_menu align-items-center">
            <li className="nav-item">
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? `active ${linkClasses}` : `${linkClasses}`
                }
              >
                Home
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/courses"
                className={({ isActive }) =>
                  isActive ? `active ${linkClasses}` : `${linkClasses}`
                }
              >
                All Courses
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/about-us"
                className={({ isActive }) =>
                  isActive ? `active ${linkClasses}` : `${linkClasses}`
                }
              >
                About Us
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                to="/blogs"
                className={({ isActive }) =>
                  isActive ? `active ${linkClasses}` : `${linkClasses}`
                }
              >
                Blog
              </NavLink>
            </li>
            
            {Object.keys(userSession).length == 0 ? <><li className="nav-item">
              <Link className="nav-link login-link" to="/?action=login">
                Login
              </Link>
            </li>
            <li className="nav-item">
              <Link
                className="nav-link btn btn-outline-primary signup-link"
                to="/?action=register"
              >
                Join For Free
              </Link>
            </li></>: 
            <li className="nav-item">
            <ProfileDropdown userSession={userSession} />
          </li>
            }
            
            <li className="nav-item d-flex align-items-center position-relative cart_btn">
              <button type="button" className="btn btn-sm btn-transparent position-relative">
                <FontAwesomeIcon icon={faCartShopping} color="#0056d2" fontSize={20} />
                {cart.length>0 && <span className="badge bg-primary position-absolute">{cart.length}</span>}
              </button>

              <Cart className='position-absolute bg-white p-2 shadow-lg d-none' />
            </li>
          </ul>
          {/* <!-- right menu ends --> */}
        </div>
      </nav>
      {/* <!-- lg navbar ends --> */}

      {/* <!-- mobile menu starts --> */}
    </>
  );
}
