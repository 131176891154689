import React from "react";
import { useSelector } from "react-redux";

export default function UnderDevelopmentSection() {
  const userSession = useSelector((state) => state.userSession);

  return (
    <section>
      <div className="container text-center">
        <h3 className="text-center">
          {`${
            Object.entries(userSession).length === 0
              ? "Hey,"
              : "Hey " + userSession.name + ","
          } `}
        </h3>
        <img
          src="/under-development.png"
          alt="under development"
          style={{ maxWidth: "400px" }}
          className="mx-auto img-fuild"
        />
        <h4 className="mt-5">This page is in under development</h4>
      </div>
    </section>
  );
}
