import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ScrollableHorizontalMenu from "../../component/ScrollableHorizontalMenu";
import UnderDevelopmentSection from "../../component/UnderDevelopmentSection";
import { Tab, Tabs } from "react-bootstrap";
import { useState } from "react";

import NativeLazyImage from "../../component/NativeLazyImage";
import UpdateProfile from "./component/UpdateProfile";
import ChangePassword from "./component/ChangePassword";

export default function ProfilePage() {
  const [key, setKey] = useState("profile");

  return (
    <main>
      <Helmet>
        <title>Profile | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <ScrollableHorizontalMenu />

      <section>
        <div className="container">
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
          >
            <Tab eventKey="profile" title="Profile">
              <UpdateProfile />
            </Tab>
            <Tab eventKey="change-password" title="Change Password">
              <ChangePassword />
            </Tab>
          </Tabs>
        </div>
      </section>
    </main>
  );
}
