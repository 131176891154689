import { FilledWhiteBtnLink } from "./FilledBtn";
import { WhiteOutlineBtnLink } from "./OutlineBtn";
import NativeLazyImage from "./NativeLazyImage";

export default function Hero({ title, desc }) {
  return (
    <section className="hero pt-md-0 pb-0 pb-md-5">
      <div className="container">
        <div className="row">
          <div className="col-md-6 d-flex align-items-center">
            <div>
              <h1 className="text-white mb-2 text-center text-md-start mt-lg-2 mt-xl-0">
                Unwrap new career skills. IALM Plus for INR 7999.
              </h1>
              <p className="text-white text-center text-md-start">
                Get unlimited access to 7,000+ learning programs from Google,
                Microsoft, IBM, and more, for just INR 7999.
              </p>
              <div className="d-flex justify-content-center justify-content-md-start">
                <FilledWhiteBtnLink
                  label="Claim Your Offer"
                  link={"#"}
                  classes="me-2"
                />

                <WhiteOutlineBtnLink label="Join For Free" link={"#"} />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <NativeLazyImage src="/img/slides/slide.png" classes="img-fluid" />
          </div>
        </div>
      </div>
    </section>
  );
}
