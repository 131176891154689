import React from "react";
import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import RawHTML from "./../../../component/RawHTML";
import AxiosClient from "../../../client/AxiosClient";
import { API_MARK_LESSON_AS_COMPLETE } from "../../../ApiEndPoints";
import { useRef } from "react";
import { useEffect } from "react";

import "./../styles/ShowLesson.style.css";
import { FilledPrimaryBtnLink } from "../../../component/FilledBtn";

export default function ShowLesson({ lesson }) {
  console.log("video lesson data", lesson);
  let host = lesson.host.toLowerCase();
  if (host === "self") {
    return <ShowVideoLesson video_src={lesson.video_url} {...lesson} />;
  } else if (host === "pdf") {
    return <ShowPdfLesson pdf_src={lesson.video_url} />;
  } else if (host === "audio") {
    return <ShowAudioLesson audio_src={lesson.video_url} />;
  } else if (host == "link" || host == "url") {
    return <ShowExternalLink link={lesson.video_url} />;
  } else {
    return <ShowQuizzLesson lesson={lesson} />;
  }
}

function ShowVideoLesson({ video_src, rest }) {
  const markAsCompleted = () => {
    alert("marking lesson as complete");
    AxiosClient.post(API_MARK_LESSON_AS_COMPLETE, {
      params: {
        course_id: rest.course_id,
        lesson_id: rest.id,
      },
    })
      .then((response) => {
        console.log("Lesson complete api response: ", response);
      })
      .catch((err) => {
        console.error("Getting error while marking lesson as complete", err);
      });
  };

  const playerRef = useRef();

  const plyrProps = {
    source: {
      type: "video",
      sources: [
        {
          src: video_src,
          //   type: "video/mp4",
          size: 720,
        },
      ],
    },
    options: {
      //   debug: true,
    },
  };

  useEffect(() => {
    console.log(playerRef);
    if (playerRef.current != null) {
      let player = playerRef.current;

      // player.addEventListener("ended", () => {
      //   console.log(`Video is finished`);
      // });
    }
  }, []);

  return (
    <div className="show-lesson">
      <Plyr {...plyrProps} ref={playerRef} />
    </div>
  );
}

function ShowPdfLesson({ pdf_src }) {
  return (
    <div className="show-pdf-lesson">
      <embed src={pdf_src} width="800" height="600" type="application/pdf" />
      <p>
        <a href={pdf_src} target="_blank">
          Download File
        </a>
      </p>
    </div>
  );
}

function ShowAudioLesson({ audio_src }) {
  return (
    <div className="show-audio-lesson d-flex align-items-center justify-content-center">
      <audio controls>
        <source src={audio_src} />
        Your browser does not support the audio element.
      </audio>
    </div>
  );
}

function ShowExternalLink({ link }) {
  return (
    <div className="show-external-link py-5">
      <h5>Please open below link to get this lesson contents</h5>
      <a href={link} target="_blank">
        Visit
      </a>
    </div>
  );
}

function ShowQuizzLesson({ lesson }) {
  console.log("quiizz lesson data: ", lesson);

  let quizz =
    typeof lesson.quiz_for_api[0] != "undefined"
      ? lesson.quiz_for_api[0]
      : lesson.quiz_for_api;

  return (
    <div className="show-quizz-lesson py-5">
      <span className="bg-primary p-2 text-white rounded-1 mb-2 d-inline-block">
        Quizz
      </span>
      <h5>{quizz.title}</h5>
      <p className="lead">
        <strong>Instructions: </strong>
        <RawHTML
          rawHTML={`<p className="lead">${quizz.instruction} </p>`}
        ></RawHTML>
        <FilledPrimaryBtnLink
          label={"Start the Quizz"}
          link={`/my-course/take-quizz/${lesson.course_id}/${quizz.id}`}
        />
      </p>
    </div>
  );
}
