import React from "react";
import NativeLazyImage from "./NativeLazyImage";
import "./../styles/photo_frame.style.css";

export default function PhotoFrame({ src, alt, classes }) {
  return (
    <div className={`photo-frame ${classes}`}>
      <NativeLazyImage src={src} alt={alt} />
    </div>
  );
}
