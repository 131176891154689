import React from "react";
import NativeLazyImage from "../../../component/NativeLazyImage";

import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import Loader from "../../../component/Loader";

export default function WatchCourseHeader({
  course_title = "",
  courseCompletion = "",
}) {
  console.log("course completion: ", courseCompletion);
  return (
    <div className="watch-course-header bg-lightaccent py-2">
      <div className="container">
        <div class="row align-items-center">
          <div className="col-md-8">
            <h5 className="mb-0">
              {course_title === "" ? "Loading ..." : course_title}{" "}
            </h5>
          </div>
          <div className="col-md-4 ">
            <div className="course-progress text-center d-flex align-items-center w-100">
              <div>
                {courseCompletion === "" ? (
                  <Loader />
                ) : (
                  <CircularProgressbar
                    value={courseCompletion}
                    text={`${courseCompletion}%`}
                    strokeWidth={10}
                    className=""
                  />
                )}
              </div>
              <div className="lead ms-2 d-inline-block w-100 text-start">
                Course Progress
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
