import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./../styles/searchbar.style.css";

import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import Loader from "./Loader";
import AxiosClient from "./../client/AxiosClient";
import { API_SEARCH_COURSE } from "../ApiEndPoints";
import { Link, useLocation, useParams } from "react-router-dom";

export default function Searchbar() {
  const [query, setQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const location = useLocation();

  console.log("Params: ", location);

  useEffect(() => {
    if (query.trim().length >= 3) {
      setLoading(true);
      AxiosClient.get(API_SEARCH_COURSE, {
        params: { term: query },
      })
        .then((response) => {
          setData(response.data);

          console.log(response.data);
        })
        .catch(() => {
          setData({ message: "Someting went wrong! Please try again" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setData(null);
    }
  }, [query]);

  useEffect(() => {
    setQuery("");
  }, [location]);

  console.log(query);

  const inputRef = useRef();

  return (
    <div className="search-bar">
      <form className="d-flex position-relative">
        <input
          className="form-control me-2 _search-field"
          type="text"
          placeholder="Search for a course"
          aria-label="Search for a course"
          value={query}
          onChange={({ target }) => setQuery(target.value)}
          ref={inputRef}
        />
        <button
          className="btn btn-primary _search_submit position-absolute"
          type="button"
          onClick={() => {
            inputRef.current.focus();
          }}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="_icon" />
        </button>
      </form>

      {query.trim().length >= 1 && (
        <div class="_result p-2 rounded-2">
          <p className="mb-1">Search result</p>

          {data != null && data.data.length > 0 && (
            <ul className="list-group list-group-flush">
              {data.data.map((item, index) => {
                return (
                  <li class="list-group-item" key={`search-result-${index}`}>
                    <Link to={`/courses/${item.slug}`}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
          )}

          {loading && (
            <div className="text-center">
              <Loader />
            </div>
          )}

          {loading === false && (
            <p className="lead">
              {query.length < 3 && "Enter 3 or more character to search"}
              {!loading &&
                query.length >= 3 &&
                data != null &&
                `${data.message}`}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
