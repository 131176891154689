import React from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { logout } from "../redux/slice/UserSessionSlice";
import { useNavigate } from "react-router-dom";

export default function LogoutButton({ classes = "" }) {
  const dispatch = useDispatch();
  const navigateTo = useNavigate();

  function handleForm(event) {
    event.preventDefault();

    dispatch(logout());

    localStorage.clear();
    navigateTo("/");
  }

  return (
    <Form onSubmit={handleForm}>
      <button type="submit" className={`btn btn-transparent theme-btn p-0`}>
        Logout
      </button>
    </Form>
  );
}
