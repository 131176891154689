import { createSlice } from "@reduxjs/toolkit";

const cartSlice = createSlice({
  initialState: [],
  name: "Cart",
  reducers: {
    addToCart: (state = [], action) => {
      console.log("adding: ", action);

      const checkItemExists = state.some(
        (item) => item.id === action.payload.id
      );
      console.log("item exists: ", checkItemExists);

      if (checkItemExists) {
        return [...state];
      }

      return [
        ...state,
        {
          id: action.payload.id,
          img: action.payload.img,
          name: action.payload.name,
          instructorName: action.payload.instructorName,
          discountPrice: action.payload.discountPrice,
          mrpPrice: action.payload.mrpPrice,
        },
      ];
    },
    removeFromCart: (state, action) => {
      console.log("Payload: ", action.payload);
      return state.filter((item) => item.id !== action.payload);
    },
    clearCart: (state, action) => {
      return [];
    },
  },
});

export const { addToCart, removeFromCart, clearCart } = cartSlice.actions;
export const cartReducer = cartSlice.reducer;
