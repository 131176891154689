import React, { useEffect, useState } from "react";
import AxiosClient from "../client/AxiosClient";
import { API_FOOTER_QUICK_LINKS } from "../ApiEndPoints";
import Loader from "./Loader";

export default function FooterQuickLinks() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_FOOTER_QUICK_LINKS)
      .then((response) => {
        let responseData = response.data.data;
        console.log("responseData", responseData);
        setData(responseData);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error while fetchign footer quick links: ", error);
      });
  }, []);

  return (
    <div>
      {loading ? (
        <Loader classes="text-primary" />
      ) : (
        data != null &&
        data.map((row, index) => {
          return (
            <a
              key={`quick-link-${index}`}
              href={row.link}
              target={row.open_in_new_tab === 1 ? "_blank" : "_self"}
              className="list-group-item list-group-item-action"
            >
              {row.label}
            </a>
          );
        })
      )}
    </div>
  );
}
