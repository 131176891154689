import { Link } from "react-router-dom";
import NativeLazyImage from "./NativeLazyImage";

export default function HorizontalImageInfoTile({
  link,
  thumbnail,
  title,
  small_desc,
}) {
  return (
    <div className="col-sm-6 col-md-6 col-lg-4">
      <Link to={link} className="text-decoration-none">
        <div className="card d-flex flex-row tile-style-1 border-0 overflow-hidden my-2">
          <div className="_img_wrap">
            <NativeLazyImage
              src={thumbnail}
              alt={title}
              classes="card-img-top"
            />
          </div>
          <div className="card-body">
            <h5 className="card-title mb-0">{title}</h5>
            <p className="card-text _count">{small_desc}</p>
          </div>
        </div>
      </Link>
    </div>
  );
}
