import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "./Loader";

export function FilledWhiteBtn({
  label,
  type = "button",
  classes = "",
  clickHandler,
  isLoading = false,
}) {
  return (
    <Button
      type={type}
      onClick={clickHandler}
      variant="btn btn-light theme-btn bg-light text-primary"
      className={`${classes}`}
    >
      {label} {isLoading && <Loader classes="text-white" />}
    </Button>
  );
}

export function FilledWhiteBtnLink({ label, link, classes = "" }) {
  return (
    <Link to={link} className={`btn btn-light theme-btn bg-light ${classes}`}>
      {label}
    </Link>
  );
}

export function FilledPrimaryBtn({
  label,

  classes,

  clickHandler,
  type = "button",
  isLoading = false,
}) {
  return (
    <Button
      type={type}
      onClick={clickHandler}
      className={`btn btn-light theme-btn bg-light ${classes} text-white d-flex justify-content-center align-items-center`}
      disabled={isLoading}
    >
      {label} {isLoading && <Loader classes="text-white ms-1" />}
    </Button>
  );
}

export function FilledPrimaryBtnLink({ label, link, classes }) {
  return (
    <Link
      to={link}
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
    >
      {label}
    </Link>
  );
}

export function IconFilledPrimaryBtn({
  label,

  fontAwesomeIcon,
  classes,
  type = "button",
  isLoading = false,
  clickHandler = function () {},
}) {
  return (
    <Button
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
      type={type}
      onClick={clickHandler}
      disabled={isLoading}
    >
      {isLoading ? (
        <Loader classes="text-white" />
      ) : (
        <>
          {label !== "" && <span className="_label me-2">{label}</span>}
          <FontAwesomeIcon icon={fontAwesomeIcon} />
        </>
      )}
    </Button>
  );
}

export function IconFilledPrimaryBtnLink({
  label,

  fontAwesomeIcon,
  classes,
  link = "#",
}) {
  return (
    <Link
      className={`btn btn-primary theme-btn bg-primary ${classes}`}
      to={link}
    >
      {label !== "" && <span className="_label me-2">{label}</span>}
      <FontAwesomeIcon icon={fontAwesomeIcon} />
    </Link>
  );
}

export function CartButton({
  label,

  classes,

  clickHandler,
  type = "button",
  disable = false,
}) {
  console.log("disabled: ", disable);
  return (
    <Button
      type={type}
      onClick={clickHandler}
      className={`btn btn-light theme-btn bg-light ${classes} text-white`}
      disabled={disable}
    >
      {label}
    </Button>
  );
}
