import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FilledPrimaryBtn } from "./FilledBtn";
import { useRazorpay, RazorpayOrderOptions } from "react-razorpay";
import AxiosClient from "../client/AxiosClient";
import { API_CONFIRM_ORDER, API_INIT_ORDER } from "../ApiEndPoints";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DismissableAlert from "./DismissableAlert";
import { clearCart } from "../redux/slice/CartSlice";

export default function CheckoutWithRazorPay() {
  const [loading, setLoading] = useState(false);
  const [checkoutLabel, setCheckoutLabel] = useState("PROCEED TO CHECKOUT");

  const navigateTo = useNavigate();
  const dispatch = useDispatch();

  const userSession = useSelector((state) => state.userSession);
  let orderData = {
    orderId: "",
    paybleAmount: 0,
    trackingId: "",
  };

  const [alert, setAlert] = useState({ alert: false, title: "", msg: "" });

  function initPayment(event) {
    event.preventDefault();
    setAlert({ alert: false, title: "", msg: "" });

    setLoading(true);
    AxiosClient.post(API_INIT_ORDER)
      .then((response) => {
        if (response.data.success === true && response.data.type == "Paid") {
          orderData = {
            orderId: response.data.order_id,
            paybleAmount: response.data.payble_amount,
            trackingId: response.data.trackingId,
          };

          handlePayment();
        } else {
          // error
        }
      })
      .catch(() => {})
      .finally(() => {
        setCheckoutLabel("PROCEED TO CHECKOUT");
      });
  }

  const { error, isLoading, Razorpay } = useRazorpay();

  const handlePayment = () => {
    console.log("Order data, ", orderData);
    setLoading(true);
    setCheckoutLabel("CONFIRMING PAYMENT");

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY_ID,
      amount: parseFloat(orderData.paybleAmount).toFixed(2) * 100, // Amount in paise
      currency: "INR",
      name: process.env.REACT_APP_PAYMENT_GATEWAY_MERCHANT,
      description: "Course payment agains tracking no",
      order_id: orderData.orderId, // Generate order_id on server
      handler: (response) => {
        // confirming payment status from gateway

        AxiosClient.post(API_CONFIRM_ORDER + "RazorPay", response)
          .then((response) => {
            setCheckoutLabel("PAID SUCCESSFULLY");
            setLoading(true);

            // console.log(response);
            dispatch(clearCart());

            navigateTo("/dashboard");
          })
          .catch(() => {
            setLoading(false);
            setAlert({
              alert: true,
              title: "Error",
              msg: "Something went wrong! Please try again",
            });

            setCheckoutLabel("PROCEED TO CHECKOUT");

            navigateTo("/dashboard");
          })
          .finally(() => {});
      },

      modal: {
        ondismiss: function () {
          setLoading(false);
          setCheckoutLabel("PROCEED TO PAYMENT");
          // Handle payment discard
        },
      },

      prefill: {
        name: userSession.name,
        email: userSession.email,
        contact: userSession.phone,
      },
      theme: {
        color: "#0056d2",
      },
    };

    const razorpayInstance = new Razorpay(options);

    razorpayInstance.on("payment.failed", function (response) {
      setAlert({
        alert: true,
        title: "Something went wrong",
        msg: response.error.description,
      });

      setCheckoutLabel("PROCEED TO CHECKOUT");
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });

    razorpayInstance.open();
  };

  return (
    <div>
      <Form onSubmit={initPayment}>
        {alert.alert === true && (
          <DismissableAlert
            varient="warning"
            title={alert.title}
            desc={alert.msg}
            classes=""
          />
        )}

        <FilledPrimaryBtn
          label={checkoutLabel}
          type="submit"
          isLoading={loading}
          classes="w-100 text-center mt-4"
        />
      </Form>
    </div>
  );
}
