import axios from "axios";


const AxiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Replace with your API base URL
});

// Request Interceptor
AxiosClient.interceptors.request.use(
  (config) => {
    // Modify the request config before it is sent
    const token = localStorage.getItem("token"); // Example: Get token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config; // Ensure to return the modified config
  },
  (error) => {
    // Handle request error
    return Promise.reject(error);
  }
);

// Response Interceptor
AxiosClient.interceptors.response.use(
  (response) => {
    // Handle the response data

    return response;
  },
  (error) => {
    // Handle response errors globally
    if (error.response && error.response.status === 401) {
      console.log("Unauthorized! Redirecting to login...");

      window.location.href="/?action=login"
      // Example: Redirect to login
    }

    if (error.response && error.response.status === 404) {
      // redirecting to 404
      // window.location.href = "/page-not-found";
    }

    return Promise.reject(error);
  }
);

export default AxiosClient;
