"use client";

import React, { useState } from "react";
import { withErrorBoundary } from "react-error-boundary";
import Fallback from "./Fallback";

export default function ErrorBoundaryWrap({
  component,
  fallBackComponent = Fallback,
  ...rest
}) {
  const [resetKey, updateResetKey] = useState(0);

  const handleReset = () => {
    updateResetKey(resetKey + 1);
  };

  const WithErrorBoundaryWrap = withErrorBoundary(component, {
    FallbackComponent: fallBackComponent,
    onError: (error, info) => {
      console.log("An error caught by error boudary!");
      console.log("Above error: ", error);
      console.log("Above info: ", info);
    },
    onReset: handleReset,
  });

  return <WithErrorBoundaryWrap {...rest} />;
}
