import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AxiosClient from "./../client/AxiosClient";
import { API_SPONSORS } from "../ApiEndPoints";
import Loader from "./../component/Loader";
import NativeLazyImage from "./../component/NativeLazyImage";

export default function LogoRow() {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  useEffect(() => {
    AxiosClient.get(API_SPONSORS)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((err) => {
        console.error("Error while loading sposors: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <section className="bg-lightgrey">
      <div className="container">
        <div className="title text-center">
          <h3>
            We collaborate with{" "}
            <span className="text-primary">
              325+ leading universities and companies
            </span>
          </h3>
        </div>
        <div className="mt-5">
          <div className="d-flex flex-wrap justify-content-center align-items-center partners">
            {loading ? <Loader classes="text-primary" /> : null}

            {!loading &&
              data.map((row, index) => {
                return (
                  <span
                    className="px-4 py-2 partner-logo d-inline-block"
                    key={`logo-${index}`}
                  >
                    <NativeLazyImage src={row.image} alt={row.title} />
                  </span>
                );
              })}

            {/* <Link href="#" className="px-4 py-2 partner-logo">
              <img src="/img/clients/imperial.png" />
            </Link>
            <Link href="#" className="px-4 py-2 partner-logo">
              <img src="/img/clients/google.png" />
            </Link>
            <Link href="#" className="px-4 py-2 partner-logo">
              <img src="/img/clients/duke-3.png" />
            </Link>
            <Link href="#" className="px-4 py-2 partner-logo">
              <img src="/img/clients/1000px-IBM_logo.svg.png" />
            </Link>
            <Link href="#" className="px-4 py-2 partner-logo">
              <img src="/img/clients/penn.png" />
            </Link> */}
          </div>
        </div>
      </div>
    </section>
  );
}
