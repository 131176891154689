import React from "react";
import NativeLazyImage from "../../component/NativeLazyImage";
import {
  CartButton,
  FilledPrimaryBtn,
  FilledPrimaryBtnLink,
} from "../../component/FilledBtn";
import {
  AddToWishlistBtn,
  PrimaryIconOutlineBtn,
  RemoveFromWishListBtn,
  WhiteIconOutlineBtn,
} from "../../component/OutlineBtn";
import { Accordion } from "react-bootstrap";
import AxiosClient from "../../client/AxiosClient";
import { API_GET_COURSE } from "../../ApiEndPoints";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Loader from "../../component/Loader";
import { hasValue, referToSection } from "../../helper";
import { useRef } from "react";
import RawHTML from "../../component/RawHTML";
import LessonTypeIcon from "../../component/LessonTypeIcon";
import { Helmet } from "react-helmet";
import PlayerModel from "../../component/PlayerModel";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../redux/slice/CartSlice";
import ReviewCard from "../../component/ReviewCard";
import "./../../styles/SingleCoursePage.style.css";

export default function SingleCoursePage() {
  const params = useParams();
  const [loading, setLoading] = useState(true);

  const [courseData, setCourseData] = useState(null);
  const [modules, setModules] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [wishlistStatus, setWishlistStatus] = useState(false);

  const [metaData, setMetaData] = useState({
    title: process.env.REACT_APP_NAME,
    keywords: "",
    description: "",
  });

  const aboutCourseSecRef = useRef();
  const syllabusSecRef = useRef();
  const reviewsSecRef = useRef();

  useEffect(() => {
    AxiosClient.post(`${API_GET_COURSE}${params.slug}`)
      .then((response) => {
        setCourseData(response.data.data);

        setModules(response.data.data.chapters);

        setReviews(response.data.data.reviews);

        setWishlistStatus(response.data.data.wishlisted);

        setMetaData({
          title: response.data.data.meta_title ?? metaData.title,
          keywords: response.data.data.meta_keywords ?? metaData.keywords,
          description:
            response.data.data.meta_description ?? metaData.description,
        });
      })
      .catch((err) => {
        console.error("There is an error while fetching the course: " + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug]);

  const [showPlayer, setShowPlayer] = useState(false);

  const togglePlayer = () => {
    setShowPlayer(!showPlayer);
  };

  // check is item exists in cart

  const [itemExistsInCart, setItemExistsInCart] = useState(null);
  const cartItems = useSelector((state) => state.cart);
  useEffect(() => {
    if (courseData != null) {
      let itemStatus = cartItems.some((item) => item.id === courseData.id);
      setItemExistsInCart(itemStatus);
    }
  }, [courseData]);

  const dispatch = useDispatch();

  return (
    <>
      <Helmet>
        <title>{metaData.title}</title>
        <meta name="keywords" content={metaData.keywords} />
        <meta name="description" content={metaData.description} />
      </Helmet>

      <section className="bg-lightaccent">
        <div className="container">
          <div className="row">
            <div className="col-md-6 pe-4 d-flex align-items-center position-relative">
              {courseData == null ? (
                <Loader classes="mx-auto text-primary" />
              ) : (
                <NativeLazyImage
                  src={courseData.image}
                  alt={courseData.title}
                  classes="img-fluid"
                />
              )}

              {courseData != null && courseData.trailer_link && (
                <button
                  onClick={togglePlayer}
                  className="btn btn-primary primary-filled-white-icon-btn trailer_btn position-absolute d-flex align-items-center justify-content-center position-center-center"
                  title="Watch Trailer"
                >
                  <FontAwesomeIcon icon={faPlay} fontSize={24} />
                </button>
              )}

              {courseData != null && courseData.isEnrolled === false && (
                <div className="position-absolute wishlist-btn-wrap">
                  {wishlistStatus === true ? (
                    <RemoveFromWishListBtn
                      course_id={courseData.id}
                      itemWishListStatusCallback={setWishlistStatus}
                    />
                  ) : (
                    <AddToWishlistBtn
                      course_id={courseData.id}
                      itemWishListStatusCallback={setWishlistStatus}
                    />
                  )}
                </div>
              )}
            </div>
            <div className="col-md-6">
              <h1 className="mt-3 mt-md-0 course_title">
                {courseData == null ? (
                  <Loader classes="mx-auto text-primary" />
                ) : (
                  courseData.title
                )}
              </h1>

              {courseData == null ? (
                <Loader />
              ) : (
                <div className="mt-4">
                  <ul className="lead type-none ps-0">
                    <li className="mb-2">
                      <strong>Instructors: </strong> {courseData.user.name}
                    </li>
                    <li className="mb-2">
                      <strong>Language: </strong>{" "}
                      {courseData.user.language_name}
                    </li>
                    <li className="mb-2">
                      <strong>Learners Enrolled: </strong>{" "}
                      {courseData.enrollCount} student already enrolled
                    </li>
                    <li className="mb-2">
                      <strong>Course Duration: </strong>{" "}
                      {courseData.access_limit == null
                        ? "-"
                        : courseData.access_limit}{" "}
                      Days
                    </li>
                    <li className="mb-2">
                      <strong>Course Fees (INR): </strong>{" "}
                      {hasValue(courseData.discount_price)
                        ? courseData.discount_price
                        : courseData.price}
                      /-
                    </li>

                    <div className="d-flex mt-4">
                      {courseData.isEnrolled ? (
                        <FilledPrimaryBtnLink
                          label={"Continue Course"}
                          link={`/my-courses/watch/${courseData.slug}`}
                        />
                      ) : (
                        <CartButton
                          classes={""}
                          clickHandler={() => {
                            dispatch(
                              addToCart({
                                id: courseData.id,
                                name: courseData.title,
                                instructorName: courseData.user.name,
                                mrpPrice:
                                  courseData.price === null ||
                                  courseData.price === ""
                                    ? 0
                                    : courseData.price,
                                discountPrice:
                                  courseData.discount_price === null ||
                                  courseData.discount_price === ""
                                    ? 0
                                    : courseData.discount_price,
                                img: courseData.image,
                              })
                            );

                            setItemExistsInCart(true);
                          }}
                          type="button"
                          isLoading={false}
                          label={
                            itemExistsInCart === true
                              ? "Added to Cart"
                              : "Add to Cart"
                          }
                          disable={itemExistsInCart === true ? true : false}
                        />
                      )}
                    </div>
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {courseData != null && courseData.trailer_link && (
        <PlayerModel
          show={showPlayer}
          handleClose={togglePlayer}
          video_src={courseData.trailer_link}
        />
      )}

      <section className=" py-4">
        <div className="container">
          <div className="custom-btn-group">
            <button
              type="button"
              className="btn btn-light text-primary mx-2 fw-500 mb-2"
              onClick={() => {
                referToSection(aboutCourseSecRef);
              }}
            >
              About & other important information
            </button>
            <button
              type="button"
              className="btn btn-light text-primary mx-2 fw-500 mb-2"
              onClick={() => {
                referToSection(syllabusSecRef);
              }}
            >
              Syllabus
            </button>
            <button
              type="button"
              className="btn btn-light text-primary mx-2 fw-500 mb-2"
              onClick={() => {
                referToSection(reviewsSecRef);
              }}
            >
              Feedback & Reviews
            </button>
          </div>
        </div>
      </section>

      {/* container starts */}

      <div className="section-refrence">
        <div className="container">
          {courseData == null ? (
            <section className="section pb-0">
              <Loader />
            </section>
          ) : (
            <section className="py-4" ref={aboutCourseSecRef}>
              <h4 className="bg-light p-2">
                About & other important information
              </h4>
              <RawHTML rawHTML={courseData.about} />
            </section>
          )}

          {courseData == null ? (
            <section className="py-4">
              <Loader />
            </section>
          ) : (
            <section className="py-4 syllabus" ref={syllabusSecRef}>
              <h4 className="bg-light p-2 mb-3">Syllabus</h4>

              <Accordion>
                {modules.map((chapter, index) => {
                  return (
                    <Accordion.Item eventKey={index}>
                      <Accordion.Header>
                        <div className="d-flex flex-column flex-md-row justify-content-md-between  w-100">
                          <h5 className="mb-0 ">
                            Module {index + 1}: {chapter.name}
                          </h5>
                          <p className="lead mb-0 me-2">
                            <span className="badge badge-light bg-light text-primary fw-bolder">
                              {chapter.lessons.length} Lesson/Attachement
                            </span>
                          </p>
                        </div>
                      </Accordion.Header>
                      <Accordion.Body className="lessons-wrap">
                        {chapter.lessons.length > 0 &&
                          chapter.lessons.map((lesson, lesson_index) => {
                            return lesson.quiz.length === 0 ? (
                              <div
                                className={`p-3 border-bottom border-1 `}
                                key={lesson_index}
                              >
                                <span className="_icon pe-3">
                                  <LessonTypeIcon
                                    host={lesson.host}
                                    color={"#0056d2"}
                                  />
                                </span>
                                {lesson.name}
                              </div>
                            ) : (
                              <div
                                className={`p-3 border-bottom border-1 `}
                                key={lesson_index}
                              >
                                <span className="_icon pe-3">
                                  <LessonTypeIcon
                                    host={"quizz"}
                                    color={"#0056d2"}
                                  />
                                </span>
                                {lesson.quiz[0].title}
                              </div>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  );
                })}

                {/* <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion Item #2</Accordion.Header>
                  <Accordion.Body>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                    Duis aute irure dolor in reprehenderit in voluptate velit
                    esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
                    occaecat cupidatat non proident, sunt in culpa qui officia
                    deserunt mollit anim id est laborum.
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </section>
          )}

          {courseData == null ? (
            <section className="section py-4">
              <Loader />
            </section>
          ) : (
            <section className="section pt-4" ref={reviewsSecRef}>
              <h4 className="bg-light p-2">Feedback & Reviews</h4>

              {reviews.length === 0 && <p>No reviews found</p>}
              {reviews.map((item, index) => {
                return (
                  <ReviewCard
                    key={index}
                    avatar={item.userImage}
                    name={item.userName}
                    rating={item.star}
                    review={item.comment}
                  />
                );
              })}
            </section>
          )}
        </div>
      </div>
    </>
  );
}
