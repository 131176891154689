import React from "react";
import { FilledPrimaryBtnLink } from "../component/FilledBtn";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";

export default function PageNotFound404() {
  return (
    <section className="text-center">
      <div className="lottiefile-wrap">
        <DotLottieReact src="/animation/page-not-found.json" loop autoplay />
      </div>

      <h1>It seems you have lost</h1>
      <p className="lead">Explore our courses to find the right path</p>
      <FilledPrimaryBtnLink label={"Let me Explore"} link={"/courses"} />
    </section>
  );
}
