export default function Fallback({ error, resetErrorBoundary }) {
  return (
    <div className="p-2 border border-3 border-danger bg-light text-center">
      <p className="mb-1 lead strong fw-500">Oops! Something went wrong</p>
      <button
        className="btn btn-sm btn-danger theme-btn"
        onClick={resetErrorBoundary}
      >
        Try again
      </button>
    </div>
  );
}
