import CourseCardTile from "../component/CourseCardTile";

export default function AllCourses({ courses }) {
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;
  return (
    <div className="row">
      {courses.map((row, index) => {
        return (
          <div className="col-md-4 col-lg-3" key={`course-grid-sec${index}`}>
            <CourseCardTile
              title={row.title}
              category={row.category_name}
              thumbnail={serverUrl + row.thumbnail}
              price={row.price}
              instructorName={row.instructor_name}
              discountPrice={row.discount_price}
              link={`/courses/${row.slug}`}
            />
          </div>
        );
      })}
    </div>
  );
}
