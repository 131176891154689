import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Loader from "../component/Loader";
import DismissableAlert from "../component/DismissableAlert";
import { useNavigate } from "react-router-dom";
import AxiosClient from "../client/AxiosClient";
import { API_PASSWORD_RESET, API_PASSWORD_RESET_OTP } from "../ApiEndPoints";
import TogglePasswordField from "../component/TogglePasswordField";
import {
  FilledPrimaryBtn,
  FilledPrimaryBtnLink,
} from "./../component/FilledBtn";

export default function ForgetPasswordPage() {
  const [step, changeStep] = useState(1);
  const [email, setPasswordChangeEmail] = useState("");

  return (
    <main>
      <Helmet>
        <title>Forget Password | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb title="Forget password" desc="Recover your password" />

      <section>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-5">
              {step === 1 && (
                <SendOTPForm
                  setPasswordChangeEmail={setPasswordChangeEmail}
                  changeStep={changeStep}
                />
              )}

              {step === 2 && (
                <ResetPasswordForm email={email} changeStep={changeStep} />
              )}

              {step === 3 && (
                <div className="text-center">
                  <h2 className="text-success">Success</h2>
                  <p className="lead">
                    Your password has been reset successfully
                  </p>
                  <FilledPrimaryBtnLink
                    label={`Back to login`}
                    link={`/?action=login`}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

function SendOTPForm({ setPasswordChangeEmail, changeStep }) {
  const navigateTo = useNavigate();

  const [email, setEmail] = useState("");

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  function handleSubmit(event) {
    event.preventDefault();

    setShowLoader(true);

    AxiosClient.post(API_PASSWORD_RESET_OTP, { email: email })
      .then((response) => {
        const { data: responseData } = response;

        console.log("my response data: ", responseData);

        if (responseData.success === true) {
          setPasswordChangeEmail(email);
          changeStep(2);

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.msg,
          });
        } else {
          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.msg,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: "Something went wrong! Please try again",
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });
  }

  return (
    <div>
      <h3 className="mb-0 text-center">To reset your password</h3>
      <p className="text-center">
        Enter your email and we will sent you and OTP
      </p>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            placeholder=""
            value={email}
            onChange={(event) => {
              setEmail(event.target.value);
            }}
          />
        </Form.Group>

        {showAlert.show === true && (
          <DismissableAlert
            varient={showAlert.type}
            title={showAlert.title}
            desc={showAlert.desc}
          />
        )}

        <Button
          type="submit"
          className="btn btn-primary theme-btn bg-primary d-block w-100 mt-4"
          disabled={showLoader}
        >
          <span className="me-2">Send me OTP</span>
          {showLoader && <Loader classes="text-white" />}
        </Button>

        <p className="d-flex justify-content-center flex-wrap mt-4 text-sm">
          <span className="text-muted">Remember your password </span>
          <Button
            type="button"
            variant="transparent"
            className="p-0 m-0 ms-1 text-decoration-underline link"
            onClick={() => navigateTo("/?action=login")}
          >
            Back to login
            {showLoader && <Loader classes="text-white" />}
          </Button>
        </p>

        <hr className="my-4" />
        <p className="d-flex justify-content-center flex-wrap mt-3 text-sm">
          <span className="text-muted">Having trouble?</span>
          <Button
            type="button"
            variant="transparent"
            className="p-0 m-0 ms-1 text-decoration-underline link"
          >
            Visit helpcenter
          </Button>
        </p>
      </Form>
    </div>
  );
}

function ResetPasswordForm({ email, changeStep }) {
  const navigateTo = useNavigate();

  const [formValues, setFormValues] = useState({
    otp: "",
    password: "",
  });

  const [formErrors, setFormErrors] = useState({
    otp: "",
    password: "",
  });

  const [showLoader, setShowLoader] = useState(false);
  const [showAlert, setshowAlert] = useState({
    show: false,
    type: "",
    title: "",
    desc: "",
  });

  function setPasswordField(password) {
    setFormValues({ ...formValues, password: password });
  }

  function handleSubmit(event) {
    event.preventDefault();

    setShowLoader(true);

    AxiosClient.post(API_PASSWORD_RESET, {
      ...formValues,
      confirm_password: formValues.password,
      email: email,
    })
      .then((response) => {
        const { data: responseData } = response;

        if (responseData.success === true) {
          setFormErrors({
            otp: "",
            password: "",
          });

          setshowAlert({
            show: true,
            type: "success",
            title: "",
            desc: responseData.message,
          });

          changeStep(3);
        } else {
          setFormErrors({
            otp:
              responseData.errors?.otp != null ? responseData.errors.otp : "",

            password:
              responseData.errors?.password != null
                ? responseData.errors.password
                : "",
          });

          setshowAlert({
            show: true,
            type: "danger",
            title: "",
            desc: responseData.msg,
          });
        }
      })
      .catch((err) => {
        console.error(err);

        setshowAlert({
          show: true,
          type: "danger",
          title: "",
          desc: "Something went wrong! Please try again",
        });

        setShowLoader(false);
      })
      .finally(() => {
        setShowLoader(false);
      });

    console.log({ ...formValues });
  }

  return (
    <>
      <h5 className="mb-0 text-center">
        We have sent an OTP on <ins>{email}</ins>
      </h5>
      <p className="text-center">
        Please check your mail and enter OTP with your new password
      </p>

      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>OTP</Form.Label>
          <Form.Control
            type="number"
            placeholder=""
            value={formValues.otp}
            onChange={(event) => {
              setFormValues({
                ...formValues,
                otp: event.target.value,
              });
            }}
          />
          <span className="validation-err text-danger">{formErrors.otp}</span>
        </Form.Group>
        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
          <Form.Label>Password</Form.Label>
          <TogglePasswordField
            password={formValues.password}
            setPassword={setPasswordField}
          />

          {formErrors.password !== "" && (
            <span className="validation-err text-danger d-block">
              {formErrors.password}
            </span>
          )}
        </Form.Group>

        {showAlert.show === true && (
          <DismissableAlert
            varient={showAlert.type}
            title={showAlert.title}
            desc={showAlert.desc}
          />
        )}

        <Button
          type="submit"
          className="btn btn-primary theme-btn bg-primary d-block w-100 mt-4"
          disabled={showLoader}
        >
          <span className="me-2">Reset my password</span>
          {showLoader && <Loader classes="text-white" />}
        </Button>

        <p className="d-flex justify-content-center flex-wrap mt-4 text-sm">
          <span className="text-muted">
            New to {process.env.REACT_APP_NAME || "Our Platform"}?
          </span>
          <Button
            type="button"
            variant="transparent"
            className="p-0 m-0 ms-1 text-decoration-underline link"
            onClick={() => navigateTo("/?action=login")}
          >
            Sign up
            {showLoader && <Loader classes="text-white" />}
          </Button>
        </p>

        <hr className="my-4" />
        <p className="d-flex justify-content-center flex-wrap mt-3 text-sm">
          <span className="text-muted">Having trouble?</span>
          <Button
            type="button"
            variant="transparent"
            className="p-0 m-0 ms-1 text-decoration-underline link"
          >
            Visit helpcenter
          </Button>
        </p>
      </Form>
    </>
  );
}
