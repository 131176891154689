"use client";
import React from "react";

import { ErrorBoundary } from "react-error-boundary";
import Fallback from "./Fallback";
import { useState } from "react";

export default function ErrorBoundryWrapParent({ children }) {
  const [reset, setReset] = useState(0);

  const resetComponent = () => {
    setReset(reset + 1);
  };

  return (
    <ErrorBoundary fallbackRender={<Fallback />} onReset={resetComponent}>
      {children}
    </ErrorBoundary>
  );
}
