import { faHeart as fasHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faHeart as farHeart } from "@fortawesome/free-regular-svg-icons";
import AxiosClient from "../client/AxiosClient";
import { API_ADD_TO_WISHLIST, API_REMOVE_FROM_WISHLIST } from "../ApiEndPoints";
import { useState } from "react";
import Loader from "./Loader";

export function WhiteOutlineBtnLink({ label, link, classes = "" }) {
  return (
    <Link to={link} className={`btn btn-outline-light theme-btn ${classes}`}>
      {label}
    </Link>
  );
}

export function PrimaryOutlineBtnLink({ label, link, classes = "" }) {
  return (
    <Link to={link} className={`btn btn-outline-primary theme-btn ${classes}`}>
      {label}
    </Link>
  );
}

export function PrimaryIconOutlineBtnLink({
  label,
  link = "#",
  fontAwesomeIcon,
  classes = "",
}) {
  return (
    <Link to={link} className={`btn btn-outline-primary theme-btn ${classes}`}>
      {label}
      <span className="_icon">
        <FontAwesomeIcon icon={fontAwesomeIcon} />
      </span>
    </Link>
  );
}

export function PrimaryIconOutlineBtn({
  label,
  type = "submit",
  handler = () => {},
  classes = "",
  fontAwesomeIcon = null,
}) {
  return (
    <button
      type={type}
      className={`btn btn-outline-primary theme-btn ${classes}`}
      onClick={handler}
    >
      {label}
      <span className="_icon">
        {fontAwesomeIcon != null && <FontAwesomeIcon icon={fontAwesomeIcon} />}
      </span>
    </button>
  );
}

export function WhiteIconOutlineBtnLink({
  label,
  link = "#",
  fontAwesomeIcon,
  classes = "",
}) {
  return (
    <Link
      to={link}
      className={`btn btn-outline-primary theme-btn ${classes} circle-btn mb-3`}
    >
      {label}
      <span className="_icon">
        <FontAwesomeIcon icon={fontAwesomeIcon} color="#ffffff" />
      </span>
    </Link>
  );
}

export function WhiteIconOutlineBtn({
  label,
  type = "button",
  clickHandler = null,
  fontAwesomeIcon,
  classes = "",
}) {
  return (
    <Button
      type={type}
      className={`btn bg-white btn-outline-primary theme-btn ${classes} circle-btn mb-3`}
      varient={"white"}
      onClick={clickHandler}
    >
      {label}
      <span className="_icon">
        <FontAwesomeIcon icon={fontAwesomeIcon} color="#ffffff" />
      </span>
    </Button>
  );
}

export function AddToWishlistBtn({
  course_id,
  itemWishListStatusCallback = null,
}) {
  const [loading, setLoading] = useState(false);

  function handler() {
    setLoading(true);

    AxiosClient.get(API_ADD_TO_WISHLIST, {
      params: { course_id: course_id },
    })
      .then((response) => {
        console.log("Added to wishlist: ", response.data);
        if (response.data.success === true) {
          console.log("added to wishlist & changed status to 1");
          itemWishListStatusCallback != null && itemWishListStatusCallback(1);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <button
      type="button"
      className="btn btn-outline btn-outline-primary wishlist-btn"
      title="Add to Wishlist"
      onClick={handler}
    >
      {loading ? <Loader /> : <FontAwesomeIcon icon={farHeart} fontSize={30} />}
    </button>
  );
}

export function RemoveFromWishListBtn({
  course_id,
  itemWishListStatusCallback = null,
}) {
  const [loading, setLoading] = useState(false);

  function handler() {
    setLoading(true);
    AxiosClient.get(API_REMOVE_FROM_WISHLIST, {
      params: { course_id: course_id },
    })
      .then((response) => {
        console.log(" remove response: ", response.data);
        if (response.data.success === true) {
          itemWishListStatusCallback != null && itemWishListStatusCallback(0);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <button
      type="button"
      className="btn btn-outline btn-outline-primary wishlist-btn"
      title="Remove from Wishlist"
      onClick={handler}
    >
      {loading ? <Loader /> : <FontAwesomeIcon icon={fasHeart} fontSize={30} />}
    </button>
  );
}
