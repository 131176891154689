import React from "react";
import { Accordion } from "react-bootstrap";
import LessonTypeIcon from "../../../component/LessonTypeIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import Loader from "../../../component/Loader";
import { API_GET_MODULES } from "../../../ApiEndPoints";
import AxiosClient from "../../../client/AxiosClient";
import { useEffect } from "react";

export default function CourseContents({ course_id, changeLesson }) {
  const [loading, setLoading] = useState(true);
  const [contents, setContents] = useState([]);

  useEffect(() => {
    AxiosClient.get(API_GET_MODULES + course_id)
      .then((response) => {
        const data = response.data.data;

        if (response.success === true) {
          setContents(data);
        }

        setContents(data);
      })
      .catch((err) => {
        console.error("getting error while fetchign course contents", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  function handleLessonChange(course_id, chapter_id, lesson_id) {
    changeLesson({
      courseId: course_id,
      chapterId: chapter_id,
      lessonId: lesson_id,
    });
  }

  return (
    <div className="course-contents">
      <h5 className="py-3 text-white px-2 bg-dark mb-0 scroll">
        Course Contents
      </h5>
      <div className="overflow-y-auto">
        {loading && (
          <div className="text-center py-2">
            <Loader />
          </div>
        )}

        {!loading && (
          <Accordion defaultActiveKey="0" flush>
            {contents.map((item, index) => {
              return (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header className="bg-light">
                    <h5 className="mb-0 fw-500">
                      <span className="fw-400 me-2">{index + 1}</span>{" "}
                      {item.name}
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body className="px-0">
                    <ul className="type-none px-0">
                      {item.lessons.map((lesson, lessonIndex) => {
                        let isQuizz = lesson.quiz_id == null ? false : true;
                        return (
                          <li
                            key={`lesson-${lessonIndex}`}
                            className="py-2 mb-2 d-flex align-items-center bg-lightaccent px-3"
                            onClick={() => {
                              handleLessonChange(
                                lesson.course_id,
                                lesson.chapter_id,
                                lesson.lesson_id
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              className="me-2"
                              color="#ffffff"
                            />
                            <LessonTypeIcon
                              host={lesson.host}
                              fontSize={18}
                              color={"#0056d2"}
                            />
                            <span className="ms-2">
                              {!isQuizz
                                ? lesson.name
                                : lesson.quiz_for_api[0].title}
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
            {/* <Accordion.Item eventKey="0">
              <Accordion.Header className="bg-light">
                <h5 className="mb-0 fw-500">
                  <span className="fw-400 me-2">1</span> Module: 1 Introduction
                </h5>
              </Accordion.Header>
              <Accordion.Body className="px-0">
                <ul className="type-none px-0">
                  <li className="py-2 mb-2 d-flex align-items-center bg-lightaccent px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="self"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">Introduction Drafting</span>
                  </li>
                  <li className="py-2 mb-2 d-flex align-items-center px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="pdf"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">INDIAN COURT STRUCTURE CHART</span>
                  </li>
                  <li className="py-2 mb-2 d-flex align-items-center px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="link"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">
                      All Sample Pings (Downloadable)
                    </span>
                  </li>
                  <li className="py-2 mb-2 d-flex align-items-center px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="Quizz"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">What is Legal Drafting?</span>
                  </li>
                  <li className="py-2 mb-2 d-flex align-items-center px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="video"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">modern legal drafting</span>
                  </li>
                  <li className="py-2 mb-0 d-flex align-items-center px-3">
                    <FontAwesomeIcon
                      icon={faCircleCheck}
                      className="me-2"
                      color="#ffffff"
                    />
                    <LessonTypeIcon
                      host="pdf"
                      fontSize={18}
                      color={"#0056d2"}
                    />
                    <span className="ms-2">Lawyer's drafting guide</span>
                  </li>
                </ul>
              </Accordion.Body>
            </Accordion.Item> */}
            {/* <Accordion.Item eventKey="2">
                  <Accordion.Header className="bg-light">
                    <h5 className="mb-0 fw-500">
                      <span className="fw-400 me-2">2</span> Module: 2 Deep div
                    </h5>
                  </Accordion.Header>
                  <Accordion.Body className="px-0">
                    <ul className="type-none px-0">
                      <li className="py-2 mb-2 d-flex align-items-center bg-light px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="self"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">Introduction Drafting</span>
                      </li>
                      <li className="py-2 mb-2 d-flex align-items-center px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="pdf"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">
                          INDIAN COURT STRUCTURE CHART
                        </span>
                      </li>
                      <li className="py-2 mb-2 d-flex align-items-center px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="link"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">
                          All Sample Pings (Downloadable)
                        </span>
                      </li>
                      <li className="py-2 mb-2 d-flex align-items-center px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="Quizz"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">What is Legal Drafting?</span>
                      </li>
                      <li className="py-2 mb-2 d-flex align-items-center px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="video"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">modern legal drafting</span>
                      </li>
                      <li className="py-2 mb-0 d-flex align-items-center px-3">
                        <FontAwesomeIcon
                          icon={faCircleCheck}
                          className="me-2"
                          color="#ffffff"
                        />
                        <LessonTypeIcon
                          host="pdf"
                          fontSize={18}
                          color={"#0056d2"}
                        />
                        <span className="ms-2">Lawyer's drafting guide</span>
                      </li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item> */}
          </Accordion>
        )}
      </div>
    </div>
  );
}
