import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { API_GET_BLOG_CATEGORIES } from "../ApiEndPoints";
import AxiosClient from "./../client/AxiosClient";
import Loader from "./Loader";
import UnderlineTitle from "./UnderlineTitle";

export default function BlogCategories() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_GET_BLOG_CATEGORIES).then((response) => {
      if (response.data.success) {
        setData(response.data.data);
        setLoading(false);
      }
    });
  }, []);

  return (
    <div className="blog-categories">
      <UnderlineTitle title="Categories" />

      {loading && (
        <div className="text-center">
          <Loader classes="text-primary" />
        </div>
      )}

      {!loading && data.length == 0 && (
        <p className="lead">No categories found</p>
      )}

      {!loading && data.length > 0 && (
        <ul className="type-none px-2">
          {data.map((item, index) => {
            return (
              <li key={`category-${index}`} className="py-2">
                <Link
                  className="text-decoration-none"
                  to={`/blogs/category/${item.slug}`}
                >
                  {item.title}
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}
