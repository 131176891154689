import { createSlice } from "@reduxjs/toolkit";

const userSessionSlice = createSlice({
  initialState: {},
  name: "User session",
  reducers: {
    login: (state = {}, { payload }) => {
      return {
        ...state,
        id: payload.id,
        name: payload.name,
        email: payload.email,
        token: payload.token,
        avatar: payload.avatar
      };
    },

    logout: (state, {payload}) => {
        return {}
    }
  },
});

export const { login, logout } = userSessionSlice.actions;
export const userSessionReducer = userSessionSlice.reducer;
