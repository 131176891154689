import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import NativeLazyImage from "../../../component/NativeLazyImage";
import "./../styles/UpdateProfile.style.css";
import { FilledPrimaryBtn } from "../../../component/FilledBtn";
import AxiosClient from "../../../client/AxiosClient";
import { API_GET_PROFILE, API_UPDATE_PROFILE } from "../../../ApiEndPoints";

export default function UpdateProfile() {
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState({
    avatar: "",
    name: "",
    email: "",
    phone: "",
  });

  const [formErrors, setFormErrors] = useState({
    avatar: "",
    name: "",
  });

  const [avatarToDisplay, setAvatarToDisplay] = useState("");

  useEffect(() => {
    setFormErrors({
      name: "",
      avatar: "",
    });

    AxiosClient.get(API_GET_PROFILE).then((response) => {
      let responseData = response.data;

      if (responseData.success == true) {
        setLoading(false);
        setFormValues({
          avatar: "",
          name: responseData.data.name,
          email: responseData.data.email,
          phone: responseData.data.phone,
        });

        setAvatarToDisplay(responseData.data.avatar);
      }
    });
  }, []);

  //   updating data

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    AxiosClient.post(API_UPDATE_PROFILE, formValues, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response) => {
        let responseData = response.data;

        if (responseData.success === true) {
        } else {
          setFormErrors({
            name: responseData.errors.name,
            avatar: responseData.errors.avatar,
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="update-profile">
      <Form onSubmit={handleSubmit}>
        <div className="d-flex flex-column align-items-center">
          <span className="_avatar border border-1 border-light shadow">
            <NativeLazyImage src={avatarToDisplay} />
          </span>
          <button
            className="btn btn-primary position-relative mt-2"
            type="button"
          >
            <input
              type="file"
              className="position-absolute w-100"
              accept="image/*"
              style={{ opacity: 0 }}
              onChange={(event) => {
                let imgUrl = URL.createObjectURL(event.target.files[0]);
                setAvatarToDisplay(imgUrl);
                setFormValues({ ...formValues, avatar: event.target.files[0] });
              }}
            />
            Change Avatar
          </button>

          {formErrors.name !== "" && (
            <span className="validation-err text-danger">
              {formErrors.avatar}
            </span>
          )}
        </div>

        <div className="col-md-4 offset-md-4 col-md-offer">
          <div class="mb-3">
            <label htmlFor="phone" class="form-label">
              Phone
            </label>
            <input
              type="text"
              class="form-control bg-light"
              id="phone"
              placeholder="Phone"
              readOnly={true}
              value={formValues.phone}
            />
          </div>
          <div class="mb-3">
            <label htmlFor="email" class="form-label">
              Email
            </label>
            <input
              type="text"
              class="form-control bg-light"
              id="email"
              placeholder="Email"
              readOnly={true}
              value={formValues.email}
            />
          </div>
          <div class="mb-3">
            <label htmlFor="name" class="form-label">
              Name
            </label>
            <input
              type="text"
              class="form-control"
              id="name"
              placeholder="Name"
              value={formValues.name}
              onChange={(event) => {
                setFormValues({ ...formValues, name: event.target.value });
              }}
            />
            {formErrors.name !== "" && (
              <span className="validation-err text-danger">
                {formErrors.name}
              </span>
            )}
          </div>
          <div className="text-center">
            <FilledPrimaryBtn
              label={"Save & Update Profile"}
              isLoading={loading}
              type="submit"
              classes="mx-auto"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
