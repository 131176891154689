import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import NativeLazyImage from "../../../component/NativeLazyImage";
import "./../styles/UpdateProfile.style.css";
import { FilledPrimaryBtn } from "../../../component/FilledBtn";
import AxiosClient from "../../../client/AxiosClient";
import {
  API_CHANGE_PASSWORD,
  API_GET_PROFILE,
  API_UPDATE_PROFILE,
} from "../../../ApiEndPoints";
import DismissableAlert from "../../../component/DismissableAlert";

export default function ChangePassword() {
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [formErrors, setFormErrors] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const [alert, setAlert] = useState({
    showAlert: false,
    title: "Error",
    desc: "",
    varient: "danger",
  });

  //   updating data

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    setFormErrors({
      old_password: "",
      new_password: "",
      confirm_password: "",
    });

    AxiosClient.post(API_CHANGE_PASSWORD, formValues)
      .then((response) => {
        let responseData = response.data;

        if (responseData.success === true) {
          setAlert({
            desc: responseData.message,
            title: "Success",
            showAlert: true,
            varient: "success",
          });

          setFormValues({
            old_password: "",
            new_password: "",
            confirm_password: "",
          });
        } else {
          setFormErrors({
            old_password: responseData.errors.old_password,
            new_password: responseData.errors.new_password,
            confirm_password: responseData.errors.confirm_password,
          });

          setAlert({
            desc: responseData.message,
            title: "Error",
            showAlert: true,
            varient: "danger",
          });
        }
      })
      .catch((error) => {
        console.log(error);

        setAlert({
          desc: "Something went wrong! Please try again",
          title: "Error",
          showAlert: true,
          varient: "danger",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="update-profile">
      <Form onSubmit={handleSubmit}>
        <div className="row">
          <div className="col-md-4">
            <div class="mb-3">
              <label htmlFor="current-password" class="form-label">
                Current Password
              </label>
              <input
                type="text"
                class="form-control"
                id="current-password"
                placeholder="Current Password"
                value={formValues.old_password}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    old_password: event.target.value,
                  });
                }}
              />
              {formErrors.old_password !== "" && (
                <span className="validation-err text-danger">
                  {formErrors.old_password}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div class="mb-3">
              <label htmlFor="new-password" class="form-label">
                New Password
              </label>
              <input
                type="text"
                class="form-control"
                id="new-password"
                placeholder="New password"
                value={formValues.new_password}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    new_password: event.target.value,
                  });
                }}
              />
              {formErrors.new_password !== "" && (
                <span className="validation-err text-danger">
                  {formErrors.new_password}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-4">
            <div class="mb-3">
              <label htmlFor="confirm-password" class="form-label">
                Confirm password
              </label>
              <input
                type="text"
                class="form-control"
                id="confirm-password"
                placeholder="Enter new password again"
                value={formValues.confirm_password}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    confirm_password: event.target.value,
                  });
                }}
              />
              {formErrors.confirm_password !== "" && (
                <span className="validation-err text-danger">
                  {formErrors.confirm_password}
                </span>
              )}
            </div>
          </div>
          <div className="col-12">
            {alert.showAlert && (
              <DismissableAlert
                title={alert.title}
                desc={alert.desc}
                varient={alert.varient}
              />
            )}

            <div className="text-center">
              <FilledPrimaryBtn
                label={"Update My Password"}
                isLoading={loading}
                type="submit"
                classes="mx-auto"
              />
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
