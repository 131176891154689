import "./../styles/RawHTML.style.css";

export default function RawHTML({ rawHTML = "", ...rest }) {
  return (
    <div
      className="raw-html"
      dangerouslySetInnerHTML={{ __html: rawHTML }}
      {...rest}
    ></div>
  );
}
