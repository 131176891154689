import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { API_ALL_CATEGORIES, API_POPULAR_COURSES } from "../ApiEndPoints";
import AxiosClient from "../client/AxiosClient";
import HorizontalImageInfoTile from "../component/HorizontalImageInfoTile";

export default function CourseCategory() {
  const [data, setData] = useState([]);
  const serverUrl = process.env.REACT_APP_SERVER_BASE_URL;

  useEffect(() => {
    AxiosClient.get(API_ALL_CATEGORIES)
      .then((response) => {
        const listData = response.data.data;
        console.log(listData);
        setData(listData);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <section className="pt-0">
      <div className="container">
        <h2 className="sec-title mb-4">Explore IALM</h2>

        <div className="row">
          {data.map((row, index) => {
            return (
              <HorizontalImageInfoTile
                link={`/category/${row.url}`}
                thumbnail={row.thumbnail}
                title={row.name}
                small_desc={`${row.courseCount}`}
                key={`course-category-${index}`}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
}
