import { useState } from "react";
import { Form } from "react-bootstrap";
import { IconFilledPrimaryBtn } from "./FilledBtn";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import AxiosClient from "./../client/AxiosClient";
import { API_SUBSCRIBE_NEWSLETTER } from "../ApiEndPoints";
import { data } from "react-router-dom";

export default function NewsletterForm() {
  const [email, setEmail] = useState("");
  const [alert, setAlert] = useState({ type: "", msg: "" });
  const [loading, setLoading] = useState(false);

  function handleForm(event) {
    event.preventDefault();
    setAlert({ type: "", msg: "" });
    setLoading(true);

    AxiosClient.post(API_SUBSCRIBE_NEWSLETTER, {
      email: email,
    })
      .then((response) => {
        const data = response.data;

        console.log(data);
        if (data.success === true) {
          setEmail("");
          setAlert({ type: "success", msg: data.message });
        } else {
          setAlert({ type: "error", msg: data.message });
        }
      })
      .catch((err) => {
        setAlert({ type: "error", msg: err });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <Form className="w-100" onSubmit={handleForm}>
      <Form.Group className="" controlId="newsletterForm">
        <div className="position-relative overflow-hidden">
          <Form.Control
            type="email"
            placeholder="Enter your email address"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <span className="_btn position-absolute pos-top-right">
            <IconFilledPrimaryBtn
              type="submit"
              isLoading={loading}
              fontAwesomeIcon={faPaperPlane}
              label={"Subscribe"}
            />
          </span>
        </div>
        <span
          className={`validation-err ${
            alert.type === "success" ? "text-success" : "text-danger"
          }`}
        >
          {alert.msg}
        </span>
      </Form.Group>
    </Form>
  );
}
