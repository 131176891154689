import React from "react";
import { Button, Modal } from "react-bootstrap";

import Plyr from "plyr-react";
import "plyr-react/plyr.css";
import "./../styles/player_model.css";

export default function PlayerModel({video_src,handleClose ,...rest}) {
  const plyrProps = {
    source: {
      type: "video",
      sources: [
        {
          src: video_src,
          //   type: "video/mp4",
          size: 720,
        },
      ],
    },
    options: {
      //   debug: true,
    },
  };

  return (
    <Modal
      {...rest}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter p"
      centered
      className="player-model "
      onHide={handleClose}
    >
      <Modal.Header
        className="bg-dark rounded-0 border-0 pt-4"
        closeButton
      ></Modal.Header>
      <Modal.Body className="bg-dark  p-4 pt-2">
        {/* <h4>Centered Modal</h4>
        <p>
          Cras mattis consectetur purus sit amet fermentum. Cras justo odio,
          dapibus ac facilisis in, egestas eget quam. Morbi leo risus, porta ac
          consectetur ac, vestibulum at eros.
        </p> */}

        <Plyr {...plyrProps} />
      </Modal.Body>
    </Modal>
  );
}
