import { Helmet } from "react-helmet";
import BreadCrumb from "../../component/BreadCrumb";

import AxiosClient from "../../client/AxiosClient";

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { API_GET_SINGLE_BLOG } from "./../../ApiEndPoints";
import NativeLazyImage from "./../../component/NativeLazyImage";
import Loader from "../../component/Loader";
import RawHTML from "../../component/RawHTML";

export default function SingleBlogPage() {
  // getting recent blogs with pagination starts
  const params = useParams();
  const blog_slug = params.blog_slug;

  const [blog, setBlog] = useState(null);

  useEffect(() => {
    AxiosClient.get(API_GET_SINGLE_BLOG + blog_slug)
      .then((response) => {
        let responseData = response.data;
        console.log("single blog data: ", responseData);
        if (responseData.success === true) {
          setBlog(responseData.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <main>
      <Helmet>
        <title>Blogs | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      {blog == null ? (
        <BreadCrumb
          title="Loading ..."
          desc="Enrich your knowelge and always stay updated with our blog"
        />
      ) : (
        <BreadCrumb title={blog.title} desc={``} />
      )}

      <section>
        <div className="container">
          <div className="row">
            <div className="col-12">
              {blog == null ? (
                <div className="text-center">
                  <Loader />
                </div>
              ) : (
                <div className="single-blog-wrap">
                  <NativeLazyImage alt="" src={blog.image} />
                  <div className="mt-3">
                    <strong className="fw-500">Published on: </strong>{" "}
                    {blog.authored_date}
                  </div>
                  <div>
                    <strong className="fw-500">By: </strong> {blog.user.name}
                  </div>

                  <div className="blog-content mt-5">
                    <RawHTML rawHTML={blog.description} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}
