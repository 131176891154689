import React from "react";
import { NavLink } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import "./../styles/scrollable_horizontal_menu.css";

export default function ScrollableHorizontalMenu({ links = [], ...rest }) {
  links =
    links.length === 0
      ? [
          {
            label: "Dashboard",
            link: "/dashboard",
          },
          {
            label: "Profile",
            link: "/profile",
          },
          // {
          //   label: "My Courses",
          //   link: "/my-courses",
          // },
          {
            label: "My Purchases",
            link: "/my-purchases",
          },
        ]
      : links;

  let linkClasses = "py-2 d-block text-decoration-none text-muted px-3";

  const { pathname } = useLocation();

  return (
    <div className="scrollable-horizontal-menu border-bottom mt-1" {...rest}>
      <div className="container">
        <ul class="list-group list-group-horizontal ">
          {links.map((item, i) => {
            console.log(linkClasses);
            return (
              <li
                class="list-group-item border-0 rounded-0 mx-2 py-0"
                key={`student-menus-${i}`}
              >
                <Link
                  to={item.link}
                  className={`${linkClasses} ${
                    pathname == item.link ? "active fw-500" : ""
                  }`}
                >
                  {item.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
