import React from "react";
import { Link } from "react-router-dom";
import NativeLazyImage from "./NativeLazyImage";
import "./../styles/image_card.style.css";
import { WhiteIconOutlineBtnLink } from "./OutlineBtn";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

export default function ImageCard({ thumbnail, title, link = "#", institute }) {
  return (
    <Link to={link} className="text-decoration-none image-card overflow-hidden">
      <div className="card pt-4 pb-2 position-relative z-1 bg-transparent overflow-hidden text-center position-relative">
        <div className="_avtar_wrap overflow-hidden mx-auto shadow">
          <NativeLazyImage
            src={thumbnail}
            alt={`${title} `}
            classes="_avatar"
          />
        </div>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <p className="card-text">{institute}</p>

          <WhiteIconOutlineBtnLink
            fontAwesomeIcon={faArrowRight}
            classes="mx-auto"
            link={link}
          />
        </div>
      </div>
    </Link>
  );
}
