import React, { useEffect, useState } from "react";
import AxiosClient from "./../../../client/AxiosClient";
import { API_INCOMPLETED_COURSE } from "../../../ApiEndPoints";
import Loader from "../../../component/Loader";
import { Link } from "react-router-dom";

export default function CurrentCoursesTable() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(API_INCOMPLETED_COURSE)
      .then((response) => {
        if (response.data.success == true) {
          setData(response.data.data);
        }
      })
      .catch((err) => {
        console.error("Error while getting incompleted courses: ", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div className="completed-courses card rounded-1">
      <div className="card-header bg-primary text-white">
        <h5 className="card-title mb-0">Current Courses</h5>
      </div>
      <div className="card-body tabe-responsive">
        <table className="table table-striped w-100">
          <thead>
            <tr>
              <th>Course No.</th>
              <th>Course Title</th>
              <th>Faculty</th>
              <th>Duration</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colspan="5" className="text-center">
                  <Loader />
                </td>
              </tr>
            )}

            {!loading &&
              data.map((item, index) => {
                return (
                  <tr>
                    <td>{item.unique_id}</td>
                    <td>
                      <Link to={`/my-courses/watch/${item.slug}`}>
                        {item.title.en}
                      </Link>
                    </td>
                    <td>{item.user.name}</td>
                    <td>{item.access_limit} days</td>
                    <td>{item.totalCompletePercentage}% COMPETED</td>
                  </tr>
                );
              })}

            {!loading && data.length === 0 && (
              <tr>
                <td className="text-center" colSpan="5">
                  No course found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
