import { Helmet } from "react-helmet";

import WatchCourseHeader from "./component/WatchCourseHeader";

import "./styles/WatchCoursePage.style.css";

import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import AxiosClient from "./../../client/AxiosClient";
import {
  API_FINAL_SUBMIT_QUIZZ,
  API_GET_QUIZZ_TO_TAKE,
  API_INIT_QUIZZ,
  API_SAVE_SINGLE_QUIZZ_ANS,
} from "../../ApiEndPoints";
import { useEffect } from "react";

import { FilledPrimaryBtn } from "./../../component/FilledBtn";
import Loader from "../../component/Loader";
import QuizzTimer from "./QuizzTimer";
import { Axios } from "axios";
import RawHTML from "./../../component/RawHTML";

export default function TakeQuizzPage() {
  const params = useParams();
  const [quizzData, setQuizzData] = useState(null);
  const [questionsData, setQuestionsData] = useState([]);
  const [questionTypes, setQuestionType] = useState([]);

  const navigateTo = useNavigate();

  let quizz_id = params.quizz_id;
  let course_id = params.course_id;

  console.timeLog(quizzData);

  useEffect(() => {
    AxiosClient.get(API_GET_QUIZZ_TO_TAKE + quizz_id).then((response) => {
      if (response.data.success === true) {
        const data = response.data;
        setQuizzData(data);
        setQuestionsData(data.data.assign);
      }
    });
  }, []);

  // handling next & previous question
  const [nextLoading, setNextLoading] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const goToNextQuestion = () => {
    setNextLoading(true);
    let questionAssign = questionsData[currentQuestionIndex];
    console.log("current question data", questionAssign);
    let postData = {
      type: questionAssign.question_bank.type,
      assign_id: questionAssign.id,
      quiz_test_id: quizzData.data.id,
      ans:
        typeof answers[currentQuestionIndex] != "undefined"
          ? [answers[currentQuestionIndex]]
          : [""],
    };

    console.log("single answer post dat", postData);

    if (currentQuestionIndex <= questionsData.length - 1) {
      // saving singe answer first
      AxiosClient.post(API_SAVE_SINGLE_QUIZZ_ANS, postData).then((response) => {
        let data = response.data;
        if (data.success === true) {
          setNextLoading(false);
        }

        if (currentQuestionIndex === questionsData.length - 1) {
          finalSubmitQuizz();
        }
      });
    }

    if (currentQuestionIndex < questionsData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const goToPreviousQuestion = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const [answers, setAnswers] = useState([]);

  const handleSaveAnswers = (answerID, questype) => {
    let tempAnswers = answers;
    tempAnswers[currentQuestionIndex] = answerID;
    setAnswers(tempAnswers);
    let tempQuesTypes = questionTypes;
    tempQuesTypes[currentQuestionIndex] = questype;
    setQuestionType(tempQuesTypes);
  };

  const quizzStartCallback = () => {
    AxiosClient.post(`${API_INIT_QUIZZ}${course_id}/${quizz_id}`).then(
      (resonse) => {
        console.log("Quizz init resposne: ", resonse.data);
      }
    );
  };
  const quizzCompleteCallback = () => {
    finalSubmitQuizz();
  };

  const finalSubmitQuizz = () => {
    AxiosClient.post(`${API_FINAL_SUBMIT_QUIZZ}`, {
      quiz_test_id: quizz_id,
      type: questionTypes,
    }).then((resonse) => {
      console.log("Quizz final submit resposne: ", resonse.data);

      navigateTo(
        `/my-course/quizz-result/${course_id}/${resonse.data.data.answer_id}`
      );
      // navigate to result page
    });
  };

  return (
    <main>
      <Helmet>
        <title>Taking ... | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <section className="py-0">
        {/* header starts */}

        <div className="watch-course-header bg-lightaccent py-2">
          <div className="container">
            <div class="row align-items-center">
              <div className="col-md-8">
                <h5 className="mb-0">
                  {quizzData == null ? <Loader /> : quizzData.data.title}
                </h5>
              </div>
              <div className="col-md-4 ">
                <div className="course-progress text-center d-flex align-items-center w-100">
                  <div>
                    {quizzData == null ? (
                      <h4 className="mb-0">00:00</h4>
                    ) : (
                      <QuizzTimer
                        onCompleteCallBack={quizzCompleteCallback}
                        onStartCallback={quizzStartCallback}
                        timeInMinutes={quizzData.data.question_time}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* header ends */}
      </section>
      <section>
        <div className="container">
          {/* question starts */}

          <h3>
            Question {currentQuestionIndex + 1} of {questionsData.length}
          </h3>
          <div class="card">
            {questionsData.length === 0 ? (
              <Loader />
            ) : (
              <div class="card-body">
                <div className="d-flex">
                  <strong>{currentQuestionIndex + 1}: </strong>
                  <RawHTML
                    rawHTML={
                      questionsData[currentQuestionIndex].question_bank.question
                    }
                  />
                </div>

                <div className="choices-wrap">
                  <SingleChoiceOptions
                    choices={
                      questionsData[currentQuestionIndex].question_bank
                        .question_mu
                    }
                    handleSaveAnswers={handleSaveAnswers}
                    answers={answers}
                    currentQuestionIndex={currentQuestionIndex}
                    setQuestionType={setQuestionType}
                  />
                </div>

                <div className="d-flex justify-content-between mt-4">
                  <FilledPrimaryBtn
                    classes={"btn-sm"}
                    clickHandler={goToPreviousQuestion}
                    type="button"
                    label={"Previous"}
                  />
                  <FilledPrimaryBtn
                    classes={"btn-sm"}
                    clickHandler={goToNextQuestion}
                    type="button"
                    label={"Next"}
                    isLoading={nextLoading}
                  />
                </div>
              </div>
            )}
          </div>
          {/* question ends */}
        </div>
      </section>
    </main>
  );
}

// function MultipleChoiceOptions({
//   choices,
//   handleSaveAnswers,
//   answers,
//   currentQuestionIndex,
// }) {
//   const ans = [];

//   const options = [];

//   return (
//     <div className="multiple-choice-options-wrap">
//       {options.map((item, index) => {
//         return (
//           <label
//             className="d-block"
//             htmlFor={`multiple-form-${index}`}
//             key={`multiple-choice-${index}`}
//           >
//             <input
//               type="checkbox"
//               className="form-check-input"
//               onChange={() => {
//                 handleSaveAnswers();
//               }}
//               value={item.id}
//               checked={item.id === answers[currentQuestionIndex] ? true : false}
//             />

//           </label>
//         );
//       })}
//     </div>
//   );
// }

function SingleChoiceOptions({
  choices,
  handleSaveAnswers,
  answers,
  currentQuestionIndex,
}) {
  const [refreshState, setRefreshState] = useState(0);

  return (
    <div className="multiple-choice-options-wrap">
      {choices.map((item, index) => {
        return (
          <label
            className="d-block mb-2"
            htmlFor={`multiple-form-${index}`}
            key={`multiple-choice-${index}`}
          >
            <input
              id={`multiple-form-${index}`}
              type="radio"
              className="form-check-input"
              name="ans"
              onChange={() => {
                handleSaveAnswers(item.id, item.type);

                setRefreshState(refreshState + 1);
              }}
              value={item.id}
              checked={item.id === answers[currentQuestionIndex] ? true : false}
            />

            <span className="ms-2">{item.title}</span>
          </label>
        );
      })}
    </div>
  );
}
