import { REACT_APP_ABBR_NAME, REACT_APP_FULL_NAME } from "../Constant";
import { FilledPrimaryBtn } from "./FilledBtn";

export default function Hero2({
  title,
  desc,
  rightBannerCss,
  btnLabel = "Regiter now",
  handler,
}) {
  return (
    <section
      className="bg-light py-0"
      style={{ backgroundImage: "url(/img/backgrounds/waves-pattern.png)" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-6 py-5 my-5">
            <h1 className="">{title}</h1>
            <p className="lead fw-500 ">{desc}</p>

            <FilledPrimaryBtn
              clickHandler={handler}
              label={btnLabel}
              classes="mt-3 text-white"
            />
          </div>

          <div className="col-md-6" style={rightBannerCss}>
            {/* <img
              src="/img/man-pointing-on-book.png"
              className="img-fluid"
              alt="man pointing to book"
            /> */}
          </div>
        </div>
      </div>
    </section>
  );
}
