import {
  faFileAudio,
  faFilePdf,
  faLink,
  faPuzzlePiece,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export default function LessonTypeIcon({ host = "", color, fontSize = 20 }) {
  host = host == null ? "" : host;

  if (host.toLowerCase() === "self") {
    return <FontAwesomeIcon icon={faVideo} color={color} fontSize={fontSize} />;
  } else if (host.toLowerCase() === "audio") {
    return (
      <FontAwesomeIcon icon={faFileAudio} color={color} fontSize={fontSize} />
    );
  } else if (host.toLowerCase() === "pdf") {
    return (
      <FontAwesomeIcon icon={faFilePdf} color={color} fontSize={fontSize} />
    );
  } else if (
    host.toLocaleLowerCase() === "url" ||
    host.toLocaleLowerCase() === "link"
  ) {
    return <FontAwesomeIcon icon={faLink} color={color} fontSize={fontSize} />;
  } else {
    return (
      <FontAwesomeIcon icon={faPuzzlePiece} color={color} fontSize={fontSize} />
    );
  }
}
