import { useSearchParams } from "react-router-dom";
import Hero from "../component/Hero";
import CourseCategory from "../sections/CourseCategory";
import CoursesGrid1 from "../sections/CoursesGrid1";
import CoursesRowWithAction from "../sections/CoursesRowWithAction";
import LogoRow from "../sections/LogoRow";
import TestimonialRow from "../sections/TestimonialRow";
import LoginFormModal from "../component/LoginFormModal";
import RegisterationFormModal from "../component/RegistrationFormModal";
import { Helmet } from "react-helmet";
import ErrorBoundaryWrap from "../error-boundries/ErrorBoundaryWrap";

export default function HomePage() {
  const [searchParams] = useSearchParams();
  const action = searchParams.get("action");

  console.log(action);

  return (
    <main>
      <Helmet>
        <title>
          Best Institute for Online Courses - Indian Academy of Law & Management{" "}
        </title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <ErrorBoundaryWrap component={Hero} />
      <ErrorBoundaryWrap component={CoursesGrid1} />

      <ErrorBoundaryWrap component={LogoRow} />
      <ErrorBoundaryWrap component={CoursesRowWithAction} />
      <ErrorBoundaryWrap component={CourseCategory} />
      <ErrorBoundaryWrap component={TestimonialRow} />

      {action === "login" && <ErrorBoundaryWrap component={LoginFormModal} />}
      {action === "register" && (
        <ErrorBoundaryWrap component={RegisterationFormModal} />
      )}
    </main>
  );
}
