import NewsletterForm from "../component/NewsletterForm";
import "./../styles/newsletter_section.style.css";

export default function NewsletterSection() {
  return (
    <section className="newsletter bg-white p-4 rounded-bottom-5 mb-5 shadow-sm">
      <div className="row">
        <div className="col-md-6 d-flex flex-column flex-md-row align-items-center">
          <span className="_icon_wrap">
            <img src="/icons/newsletter.svg" className="img-fluid" />
          </span>
          <div className="_content">
            <h3 className="mb-0 text-primary">
              Keep up to date — Get e-mail updates
            </h3>
            <p>Stay tuned for the latest coureses and offers.</p>
          </div>
        </div>

        <div className="col-md-6 d-flex align-items-center">
          <NewsletterForm />
        </div>
      </div>
    </section>
  );
}
