import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { FilledPrimaryBtn } from "./FilledBtn";
import AxiosClient from "../client/AxiosClient";
import { API_APPLY_COUPON } from "../ApiEndPoints";
import DismissableAlert from "./DismissableAlert";
import CheckoutWithRazorPay from "./CheckoutWithRazorPay";

export default function ApplyCouponCode({ changeTotal }) {
  const [formValue, setFormValue] = useState("");
  const [formError, setFormError] = useState("");

  const [loading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState(null);

  const [error, SetError] = useState({ alert: false, title: "", msg: "" });

  function handleSubmit(event) {
    event.preventDefault();
    SetError({ alert: false, title: "", msg: "" });
    setLoading(true);

    AxiosClient.post(API_APPLY_COUPON, {
      code: formValue,
    })
      .then((response) => {
        if (response.data.success == true) {
          setCoupon({
            code: response.data.data.code,
            discount: response.data.discount,
          });

          changeTotal(response.data.data.payble);
        } else {
          SetError({ alert: true, title: "Error", msg: response.data.message });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <div className="mt-3">
      {error.alert === true && (
        <DismissableAlert
          varient="warning"
          title={error.title}
          desc={error.msg}
          classes=""
        />
      )}

      <Form onSubmit={handleSubmit}>
        <div className="row">
          {coupon != null && (
            <div className="col-12 col-md-12">
              <div className="applied-coupon p-2 mb-3 d-flex justify-content-between">
                <p className="lead mb-0">
                  <strong className="bg-light p-1">{coupon.code}</strong> is
                  applied
                </p>
                <p>-{coupon.discount}</p>

                {/* <button type="button" className="btn btn-dark rounded">
                <FontAwesomeIcon icon={faXmark} color="#fffff" fontSize={18} />
              </button> */}
              </div>
            </div>
          )}

          <div className="col-9 col-md-9 pe-0">
            <input
              type="text"
              className="form-control"
              value={formValue}
              placeholder="Enter coupon code"
              onChange={(event) => setFormValue(event.target.value)}
            />
          </div>

          <div className="col-3 col-md-3">
            <FilledPrimaryBtn
              label={"Apply"}
              isLoading={loading}
              type="submit"
            />
          </div>
        </div>
      </Form>
    </div>
  );
}
