import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import NativeLazyImage from "./NativeLazyImage";
import "./../styles/profile_dropdown.style.css";
import { Link } from "react-router-dom";
import LogoutButton from "./LogoutButton";

// The forwardRef is important!!
// Dropdown needs access to the DOM node in order to position the Menu
const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <button
    type="button"
    className="btn btn-transparent profile-dropdown d-flex align-items-center"
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
    <span className="ms-1"> &#x25bc;</span>
  </button>
));

// forwardRef again here!
// Dropdown needs access to the DOM of the Menu to measure it
const CustomMenu = React.forwardRef(
  ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
    const [value, setValue] = useState("");

    return (
      <div
        ref={ref}
        style={style}
        className={className}
        aria-labelledby={labeledBy}
      >
        <ul className="list-unstyled">
          {React.Children.toArray(children).map((child) => (
            <li className="dropdown-item">{child}</li>
          ))}
        </ul>
      </div>
    );
  }
);

export default function ProfileDropdown({ userSession }) {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        <span className="_avtar_wrap bg-light">
          <NativeLazyImage src={userSession.avatar} classes="_profile-img" />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu as={CustomMenu}>
        <Dropdown.Item eventKey="1">
          <Link
            to="dashboard"
            className="text-decoration-none text-dark py-2 d-inline-block w-100"
          >
            Dashboard
          </Link>
        </Dropdown.Item>
        <Dropdown.Item eventKey="2">
          <Link
            to="profile"
            className="text-decoration-none text-dark py-2 d-inline-block w-100"
          >
            Profile
          </Link>
        </Dropdown.Item>
        <Dropdown.Item eventKey="2">
          <Link
            to="profile"
            className="text-decoration-none text-dark py-2 d-inline-block w-100"
          >
            My Courses
          </Link>
        </Dropdown.Item>
        <Dropdown.Item eventKey="3">
          <Link
            to="my-purchases"
            className="text-decoration-none text-dark py-2 d-inline-block w-100"
          >
            My Purchases
          </Link>
        </Dropdown.Item>
        <Dropdown.Item eventKey="3">
          <Link
            to="my-logins"
            className="text-decoration-none text-dark py-2 d-inline-block w-100"
          >
            Login history
          </Link>
        </Dropdown.Item>

        <Dropdown.Divider />

        <Dropdown.Item eventKey="4" as={"li"}>
          <LogoutButton />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}
