import { Helmet } from "react-helmet";
import BreadCrumb from "../component/BreadCrumb";
import NativeLazyImage from "../component/NativeLazyImage";
import "./styles/AboutUsPage.style.css";

export default function PrivacyPolicyPage() {
  return (
    <main>
      <Helmet>
        <title>Privacy Policy | {process.env.REACT_APP_NAME}</title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      <BreadCrumb
        title="About us"
        desc="Indian Academy of Law and Management (IALM) is a unit of The Indian Legal Foundation (TILF)"
      />

      <section>
        <div className="container">
          <h4>Content coming soon</h4>
        </div>
      </section>
    </main>
  );
}
