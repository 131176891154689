import { Helmet } from "react-helmet";

import BreadCrumb from "../../component/BreadCrumb";
import ImageCard from "../../component/ImageCard";
import NativeLazyImage from "../../component/NativeLazyImage";
import PhotoFrame from "../../component/PhotoFrame";
import { useEffect } from "react";
import { API_GET_INSTRUCTOR } from "../../ApiEndPoints";
import { useParams } from "react-router-dom";
import AxiosClient from "../../client/AxiosClient";
import { useState } from "react";
import Loader from "../../component/Loader";
import RawHTML from "../../component/RawHTML";

export default function SingleFacultyPage() {
  const params = useParams();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    AxiosClient.get(`${API_GET_INSTRUCTOR}${params.username}`)
      .then((response) => {
        setData(response.data.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.username]);

  return (
    <main>
      <Helmet>
        <title>
          {data != null ? data.instructor_name : ""} |{" "}
          {process.env.REACT_APP_NAME}
        </title>
        <meta
          name="keywords"
          content="online law courses, online law courses in india, law courses in india, management course, law course, civil services, finance course, cyber security course, earn free certificate, online job oriented courses, best online law courses, best online law courses in india"
        />
        <meta
          name="description"
          content="Take online management and law courses from top institution. Learn about business law to advance your education and career with IALM Academy Today!"
        />
      </Helmet>

      {loading ? (
        <BreadCrumb
          title="..."
          desc="Meet our faculty/instructor Suresh Kumar"
        />
      ) : (
        <BreadCrumb
          title={data.instructor_name}
          desc={`Meet our faculty/instructor ${data.instructor_name}`}
        />
      )}

      <section>
        <div className="container">
          {loading && (
            <div className="text-center">
              <Loader />
            </div>
          )}

          {!loading && (
            <div className="row">
              <div className="col-md-6 px-4">
                <PhotoFrame
                  alt={data.instructor_name}
                  src={data.image}
                  classes="my-5"
                />
              </div>

              <div className="col-md-6 bg-light d-flex align-items-center px-5">
                <div>
                  <h2>{data.instructor_name}</h2>
                  <p className="lead mb-2">
                    <strong>Email: </strong>
                    {data.email}
                  </p>
                  <p className="lead mb-2">
                    <strong>Contact Number: </strong>
                    {data.phone}
                  </p>
                  <p className="lead mb-2">
                    <strong>Institute: </strong>
                    {data.institute_name}
                  </p>
                  <p className="lead mb-2">
                    <strong>State & country: </strong>
                    {data.state_name}, {data.country_name}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <section>
        {loading && (
          <div className="text-center">
            <Loader classes="text-primary" />
          </div>
        )}

        {!loading && (
          <div className="container">
            <h3>
              About <ins>{data.instructor_name}</ins>
            </h3>
            <div className="line-height-normal">
              <RawHTML rawHTML={data.about} />
            </div>
          </div>
        )}
      </section>
    </main>
  );
}
